import { Icon } from "@pavabits/components";
import { debounce } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import { COD_DELEGACIONES_CON_TODAS, PAVASAL_CONTRATISTAID, USER_ROLES } from "../../../../assets/constants/Constantes";
import CustomTextInput from "../../../../components/CustomTextInput";
import CustomCheckInput from "../../../../components/common/CustomCheckInput";
import DirtyModal from "../../../../components/common/DirtyModal/DirtyModal";
import FormTemplate from "../../../../components/common/FormTemplate/FormTemplate";
import IconButton from "../../../../components/ui/atoms/Buttons/IconButton";
import Dropdown, { DropdownProps } from "../../../../components/ui/atoms/Dropdown/Dropdown";
import FormInput from "../../../../components/ui/atoms/Input/FormInput/FormInput";
import ModalPage from "../../../../components/ui/atoms/ModalPage/ModalPage";
import SingleSuggestionInputField, { SingleSuggestionInputFieldProps } from "../../../../components/ui/atoms/SingleSuggestionInput/SingleSuggestionInput.Field";
import FormField from "../../../../components/ui/molecules/Form-field/FormField";
import { useLoading } from "../../../../context/Loading.Context";
import AdjudicacionRepository from "../../../../domain/AdjudicacionRepository";
import DelegacionesRepository from "../../../../domain/DelegacionesRepository";
import Delegacion from "../../../../domain/model/Delegacion";
import AdjudicacionApiRepository from "../../../../infraestructure/api/Adjudicacion.ApiRepository";
import CodigoPostalApiRepository from "../../../../infraestructure/api/CodigoPostal.ApiRepository";
import DelegacionesApiRepository from "../../../../infraestructure/api/Delegaciones.ApiRepository";
import { CustomButton } from "../../../../shared/components/Buttons/CustomButton";
import CustomInput from "../../../../shared/components/Inputs/CustomInput/CustomInput";
import { formatDate2String, formatDate2input } from "../../../../utils/DateUtil";
import ClausulasRepository from "../../../Clausulas/domain/ClausulasRepository";
import { ClausulaReadDto } from "../../../Clausulas/domain/model/Clausula";
import ClausulasApiRepository from "../../../Clausulas/infrastructure/api/ClausulasApiRepository";
import ClausulasOfertaRepository from "../../../ClausulasOferta/domain/ClausulasOfertaRepository";
import ClausulaOferta from "../../../ClausulasOferta/domain/model/ClausulaOferta";
import ClausulasOfertaApiRepository from "../../../ClausulasOferta/infrastructure/api/ClausulasOfertaApiRepository";
import ClausulasOfertaView from "../../../ClausulasOferta/views/ClausulasOfertaView";
import ContactosRepository from "../../../Clientes/domain/ContactosRepository";
import EstadoOfertaRepository from "../../../Clientes/domain/EstadoOfertaRepository";
import Cliente from "../../../Clientes/domain/model/Cliente";
import ContactoCliente from "../../../Clientes/domain/model/ContactosCliente";
import EstadoOferta from "../../../Clientes/domain/model/EstadoOferta";
import ContactoClienteApiRepository from "../../../Clientes/infraestructure/api/ContactosClientes.ApiRepository";
import EstadoOfertaApiRepository from "../../../Clientes/infraestructure/api/EstadoOferta.ApiRepository";
import ContratosView from "../../../Contratos/views/ContratosView";
import Pavaconfeti from "../../../Equipos/Pavaconfeti";
import EstudioApiRepository from "../../../Estudios/Infraestructure/api/Estudio.ApiRepository";
import EstudioRepository from "../../../Estudios/domain/EstudioRepository";
import Estudio from "../../../Estudios/domain/model/Estudio";
import EstudiosView from "../../../Estudios/views/EstudiosView";
import LibroEstandarRepository from "../../../LibrosEstandar/domain/LibroEstandarRepository";
import LibroEstandar from "../../../LibrosEstandar/domain/model/LibroEstandar";
import LibroEstandarApiRepository from "../../../LibrosEstandar/infrastructure/api/LibroEstandar.ApiRepository";
import LibroEstandarView from "../../../LibrosEstandar/views/LibroEstandarView";
import { AuthContext } from "../../../Login/AuthContextProvider";
import ClienteOfertaRepository from "../../../Oportunidades/domain/ClienteRepository";
import PromotorRepository from "../../../Oportunidades/domain/PromotorRepository";
import Adjudicacion from "../../../Oportunidades/domain/model/Adjudicacion";
import Licitacion from "../../../Oportunidades/domain/model/Licitacion";
import ClienteOfertaApiRepository from "../../../Oportunidades/infraestructure/api/Cliente.ApiRepository";
import PromotorApiRepository from "../../../Oportunidades/infraestructure/api/Promotor.ApiRepository";
import ProvinciaApiRepository from "../../../Oportunidades/infraestructure/api/ProvinciaApiRepository";
import PresupuestoApiRepository from "../../../Presupuestos/Infraestructure/api/Presupuesto.ApiRepository";
import PresupuestoRepository from "../../../Presupuestos/domain/PresupuestoRepository";
import Presupuesto from "../../../Presupuestos/domain/model/Presupuesto";
import PresupuestosView from "../../../Presupuestos/views/PresupuestosView";
import ComercialRepository from "../../domain/ComercialRepository";
import MotivoRepository from "../../domain/MotivoRepository";
import OfertasRepository from "../../domain/OfertasRepository";
import TipoOfertaRepository from "../../domain/TipoOfertaRepository";
import Comercial, { ComercialDto, ComercialDtoFilter } from "../../domain/model/Comercial";
import Motivo from "../../domain/model/Motivo";
import { EstadoOfertaEnum, OfertasCreationDto, OfertasDto, TipoOfertaEnum } from "../../domain/model/Ofertas";
import TipoOferta from "../../domain/model/TipoOferta";
import useOfertasForm from "../../hooks/useOfertasForm";
import ComercialApiRepository from "../../infraestructure/api/Comercial.ApiRepository";
import MotivoApiRepository from "../../infraestructure/api/Motivo.ApiRepository";
import OfertasApiRepository from "../../infraestructure/api/Ofertas.ApiRepository";
import TipoOfertaApiRepository from "../../infraestructure/api/TipoOferta.ApiRepository";
import ClonarOfertasModalSection from "../objects/CloneOfertaModalSection/ClonarOfertas.ModalSection";
import DriveUrlModal from "../objects/DriveUrlModal";
import SeguimientoOfertaView from "./SeguimientoOferta";
import ContratistaRepository from "../../../Oportunidades/domain/ContratistaRepository";
import ContratistaApiRepository from "../../../Oportunidades/infraestructure/api/Contratista.ApiRepository";

interface OfertasFormProps {
	ofertas: OfertasDto
	onSubmit: (data: OfertasCreationDto, presupuesto?: Presupuesto) => void
	handleRefreshOferta: (tab: number) => void
	tab: number,
	readOnly: boolean,
}

const OfertasFormTemplateEdit: React.FC<OfertasFormProps> = (
	{
		ofertas,
		onSubmit,
		handleRefreshOferta,
		tab,
		readOnly: readOnly,
	}: OfertasFormProps
) => {
	const { t } = useTranslation<['main']>(['main']);
	const [refreshProvincia, setRefreshProvincia] = useState<boolean>(true);

	//region dirtyform
	const [dirtySeguimientoOfertas, setDirtySeguimientoOfertas] = useState<boolean>(false);
	const handleDirtySeguimientoOfertas = (value: boolean) => setDirtySeguimientoOfertas(value);
	const [tabIndex, setTabIndex] = useState(tab ?? 0);
	const [tabIndex2Change, setTabIndex2Change] = useState(tab ?? 0);
	const changeTab = (index: number) => {
		if (!dirtySeguimientoOfertas) {
			setTabIndex(index);
		}
		else {
			setTabIndex2Change(index);
			setOpenDirtyTab(true);
		}
	}
	const [openDirty, setOpenDirty] = useState<boolean>(false);
	const [openDirtyTab, setOpenDirtyTab] = useState<boolean>(false);
	const [dataForm, setData] = useState<any>();
	const [presupuestoSelectedOferta, setPresupuestoSelectedOferta] = useState<string>();
	const [presupuestoOfertado, setPresupuestoOfertado] = useState<Presupuesto>();

	const checkDirtyForm = (data: OfertasCreationDto) => {
		if (dirtySeguimientoOfertas) {
			setOpenDirty(true);
			setData(data);
		} else {
			if (data.estadoOfertaId == 3) {
				onSubmit(data, presupuestoOfertado);
			} else {
				onSubmit(data, presupuestoContratado);
			}
		}
	}
	//endregion dirtyform

	const onSearchPromotor = useCallback((search: string) => {
		const repo: PromotorRepository = new PromotorApiRepository(getToken());
		return repo.searchPromotores({ filter: { descripcion: search } });
	}, []);

	const onSearchCliente = useCallback((search: string) => {
		const repo: ClienteOfertaRepository = new ClienteOfertaApiRepository(getToken());
		return repo.searchCliente({ filter: { descripcion: search } });
	}, []);

	const onSearchContratista = useCallback((search: string, ute?: string) => {
		const repo: ContratistaRepository = new ContratistaApiRepository(getToken());
		return repo.searchContratista({ filter: { descripcion: search, asfaltero: "", ute: ute ?? "" } });
	}, []);

	const onSearchCodigoPostal = useCallback((search: string) => {
		const repo: CodigoPostalApiRepository = new CodigoPostalApiRepository(getToken());
		return repo.fetchCodigoPostal({ filter: { nombreMunicipio: search } });
	}, []);

	const getProvinciaByCPCode = useCallback((id: string) => {
		const repo: CodigoPostalApiRepository = new CodigoPostalApiRepository(getToken());
		return repo.getCodigoPostalById(id);
	}, []);

	const onChangeCodigoPostal = (s: string) => {

		onField("codigoPostalId").onSelectValue(s[0]);

		if (!s || s.length == 0) {
			setRefreshProvincia(true);
			return;
		}

		getProvinciaByCPCode(s && s.length > 0 ? s[0].toString() : "").then((resp) => {
			onField("provinciaId").onSelectValue(resp.provinciaId);
			onField("provinciaDescripcion").onSelectValue(resp.provinciaDescripcion);
			setRefreshProvincia(false);
		});
	}

	const onSearchProvincias = useCallback((search: string) => {
		const repo: ProvinciaApiRepository = new ProvinciaApiRepository(getToken());
		return repo.searchProvincias({ filter: { descripcion: search } });
	}, []);

	const updateCloseModalContrato = () => {
		handleRefreshOferta(5);
	}

	const { getToken, hasRole, getDelegacion } = useContext(AuthContext);
	const { onField, onSubmitForm } = useOfertasForm({ initialValues: { ...ofertas, fechaOferta: formatDate2String(ofertas?.fechaOferta), ultimaModificacion: formatDate2String(ofertas?.ultimaModificacion) }, handleOnSubmit: checkDirtyForm });
	const [contactosClientes, setContactosClientes] = useState<ContactoCliente[]>();
	const [estudios, setEstudios] = useState<Estudio[]>([]);
	const [presupuestos, setPresupuestos] = useState<Presupuesto[]>([]);
	const [clausulasOferta, setClausulasOferta] = useState<ClausulaOferta[]>([]);
	const [optionsTipoOferta, setOptionsTipoOferta] = useState<TipoOferta[]>([]);
	const [optionsEstadoOferta, setOptionsEstadoOferta] = useState<EstadoOferta[]>([]);
	const [motivos, setMotivos] = useState<Motivo[]>([]);
	const [optionsDelegaciones, setOptionsDelegaciones] = useState<Delegacion[]>([]);
	const dbSavedTipoOferta: number = ofertas?.tipoOfertaId ?? TipoOfertaEnum.Ejecucion;
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [openModalDriveUrl, setOpenModalDriveUrl] = useState<boolean>(false);
	const { setLoading } = useLoading();
	const [presupuestoContratado, setPresupuestoContratado] = useState<Presupuesto>();
	const [estadoContratada, setEstadoContratada] = useState(false);
	const [openPresupuestos, setOpenPresupuestos] = useState<boolean>(false);
	const [openCambioEstados, setOpenCambioEstados] = useState<boolean>(false);
	const [selectedPresupuesto, setSelectedPresupuesto] = useState<Presupuesto>();
	const [showConffeti, setShowConffeti] = useState<boolean>(false);
	const [licitacion, setLicitacion] = useState<Licitacion>();
	const [clienteLicitacion, setClienteLicitacion] = useState<Cliente>();
	const [adjudicacion, setAdjudicacion] = useState<Adjudicacion[]>([]);
	const [tipoLicitacionSelected, setTipoLicitacionSelected] = useState(false);
	const location = useLocation();
	const [pathName, setPathName] = useState('');
	const [clausulas, setClausulas] = useState<ClausulaReadDto[]>();
	const [librosEstandar, setLibrosEstandar] = useState<LibroEstandar[]>([]);
	const [refreshLE, setRefreshLE] = useState<boolean>(false);
	const [optionsComerciales, setOptionsComerciales] = useState<Comercial[]>([]);
	const [selectedComercial, setSelectedComercial] = useState<ComercialDto>({} as ComercialDto);
	const [isOfertada, setOfertada] = useState<boolean>(ofertas.estadoOfertaId == 3);

	useEffect(() => {
		if (location) {
			let tmp = location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length);
			setPathName(tmp);
		}
	}, [location])

	const fetchContactosClientes = useCallback(
		(id: number) => {
			if (id !== undefined) {
				const repo: ContactosRepository = new ContactoClienteApiRepository(getToken());
				return repo.getByClienteId(id).then((a) => {
					setContactosClientes(a);
				});
			}
		}
		, [getToken]);

	const fetchEstudios = useCallback(
		(id: string) => {
			const repo: EstudioRepository = new EstudioApiRepository(getToken());
			return repo.getByOfertaId(id).then((estudiosList) => {
				const estudios = estudiosList.map((estudio) => {
					return { ...estudio, creationTime: estudio.creationTime ? formatDate2input(estudio.creationTime) : '' }
				});
				setEstudios(estudios);
			})
		}

		, [getToken]);

	const fetchPresupuestos = useCallback(
		(id: string) => {
			const repo: PresupuestoRepository = new PresupuestoApiRepository(getToken());
			return repo.getByOfertaId({ filter: { ofertaId: id } }).then((presupuestos) => {
				const presupuestoUpd = presupuestos.map((presupuesto) => {
					return { ...presupuesto, fechaGeneracion: presupuesto.fechaGeneracion ? formatDate2input(presupuesto.fechaGeneracion) : '', fechaEnvio: presupuesto.fechaEnvio ? formatDate2input(presupuesto.fechaEnvio) : '', lastModificationTime: presupuesto.lastModificationTime ? presupuesto.lastModificationTime : '' }
				});
				setPresupuestos(presupuestoUpd);
			});
		}
		, [getToken]);

	const fetchLibrosEstandar = useCallback(
		(id: string) => {
			const repo: LibroEstandarRepository = new LibroEstandarApiRepository(getToken());
			return repo.getAllByFilter(id).then((librosEstandar) => {
				const librosEstandarUpd = librosEstandar.map((libroEstandar) => {
					return { ...libroEstandar, lastModificationTime: libroEstandar.lastModificationTime ? libroEstandar.lastModificationTime : '' }
				});
				setLibrosEstandar(librosEstandarUpd);
				setRefreshLE(true);
			});
		}, [getToken]);

	const fetchClausulasOferta = useCallback(
		(id: string) => {
			const repo: ClausulasOfertaRepository = new ClausulasOfertaApiRepository(getToken());
			return repo.getByOfertaId({ filters: { ofertaId: id }, sortingCriteria: "orden, clausula.delegacionId desc, descripcion", maxResultCount: 100 })
				.then((clausulas: ClausulaOferta[]) => {
					setClausulasOferta(clausulas);
					let delegacionId = onField("delegacionId").value;
					if (delegacionId > 0) fetchClausulas(delegacionId, clausulas);
				});
		}
		, [getToken]);

	const fetchClausulas = useCallback((delegacionId: number, clausulasOferta: ClausulaOferta[]) => {
		const repo: ClausulasRepository = new ClausulasApiRepository(getToken());
		return repo.fetchClausulasByFilter({ filter: { delegacionId: delegacionId } })
			.then((response) => {
				let currentClausulasOfertaId = clausulasOferta.map(c => c.clausulaId);
				let clausulas = response.items.filter(c => !currentClausulasOfertaId.includes(c.id));
				setClausulas(clausulas);
			});
	}
		, [getToken]);

	const fetchTipoOferta = useCallback(
		() => {
			const repo: TipoOfertaRepository = new TipoOfertaApiRepository(getToken());
			repo.fetchTipoOfertas().then((resp) => {
				setOptionsTipoOferta(resp);
			});
		}
		, [getToken]);

	const fetchEstadoOferta = useCallback(
		() => {
			const repo: EstadoOfertaRepository = new EstadoOfertaApiRepository(getToken());
			repo.getAll().then((resp) => {
				setOptionsEstadoOferta(resp);
			});
		}
		, [getToken]);

	const fetchMotivos = useCallback(
		() => {
			const repo: MotivoRepository = new MotivoApiRepository(getToken());
			repo.getAll().then((resp) => {
				setMotivos(resp);
			});
		}
		, [getToken]);

	const fetchDelegaciones = useCallback(
		() => {
			const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
			return repo.fetchDelegaciones({
				filter: {
					ids: COD_DELEGACIONES_CON_TODAS
				}
			}).then((resp) => {
				setOptionsDelegaciones(resp);
			})
		}, [getToken])


	const fetchComerciales = useCallback((delegacionId?: number) => {

		let filters: ComercialDtoFilter = {
			filter: {
				delegacionId: delegacionId ?? onField("delegacionId").value
			}
		}
		const repo: ComercialRepository = new ComercialApiRepository(getToken());
		repo.getConFiltro(filters).then((resp) => {
			setOptionsComerciales(resp);
		});
	}, [getToken]);

	const handleClickComercial = (comercial: ComercialDto) => {
		if ((selectedComercial.id !== comercial.id)) {
			setSelectedComercial(comercial);
		} else {
			setSelectedComercial({ id: 100, descripcion: "Sin comercial", delegacionId: 0 })
		}
	}

	const fetchLicitacion = (codigo: number, isSearch: boolean = false) => {
		setLoading(true);
		const repo: OfertasRepository = new OfertasApiRepository(getToken());
		const repoAdjudicaciones: AdjudicacionRepository = new AdjudicacionApiRepository(getToken())

		repo.getLicitacionByCodigo(codigo)
			.then(resp => {
				if (resp.estado !== "ANULADA") {
					if (resp && resp.promotorId) {
						onField("promotorId").onSelectValue(resp.promotorId);
						onField("promotorDescripcion").onSelectValue(resp.promotorDescripcion);
						setLicitacion(resp);
						if (isSearch) onField("tipoOfertaId").onSelectValue(TipoOfertaEnum.Licitacion);
					}
					repoAdjudicaciones.fetchAdjudicacion(resp.codigo).then(respAdjudicacion => {
						if (respAdjudicacion && respAdjudicacion.length > 0) {
							setAdjudicacion(respAdjudicacion);
							if (respAdjudicacion.length > 1) {
								const asfaltero = respAdjudicacion.filter(p => p.asfaltero == "0");
								if (asfaltero && asfaltero.length > 0 && asfaltero[0].cif.trim() != "") {
									setTipoLicitacionSelected(false);
									setLicitacion(resp);
									if (isSearch) onField("tipoOfertaId").onSelectValue(TipoOfertaEnum.Licitacion);
								}
								else {
									setTipoLicitacionSelected(false);
									setLicitacion(resp);
									if (isSearch) onField("tipoOfertaId").onSelectValue(TipoOfertaEnum.Licitacion);
								}
							}
							else {
								if (respAdjudicacion[0].cif.trim() != "") {
									setTipoLicitacionSelected(false);
								}
								setLicitacion(resp);
							}
						} else {
							if (!onField("clienteId").value) {
								toast.info("La licitación aún no está adjudicada, no se puede asignar un cliente automáticamente a la oferta");
							}
							setLicitacion(resp);
							//setClienteLicitacion({} as Cliente)
							setTipoLicitacionSelected(false);
						}
					});
				} else {
					setLicitacion(undefined);
					toast.error("La licitación no puede estar anulada");
				}

				//tenemos que comprobar si el tipo de oferta es interna, que el adjudicatario es pavasal
				/*if(ofertaInternaSelectable()){
					toast.warning("Esta licitación no tiene como adjudicatario a PAVASAL");
				}*/

			}
			)
			.catch((error) => {
				//setLicitacion(undefined);
				toast.error("No existe licitación asociada al código introducido");
			})
			.finally(() => setLoading(false)
			);
	};

	const ofertaInternaSelectable = () => adjudicacion && adjudicacion.length > 0 && adjudicacion.filter(p => p.contratistaId == PAVASAL_CONTRATISTAID).length > 0;

	useEffect(() => { // Side Effect
		fetchEstudios(ofertas?.id !== undefined ? ofertas?.id : "");
		fetchPresupuestos(ofertas?.id !== undefined ? ofertas?.id : "");
		fetchLibrosEstandar(ofertas?.id !== undefined ? ofertas?.id : "");
		fetchContactosClientes(onField("clienteId").value);
		fetchDelegaciones();
		fetchTipoOferta();
		fetchEstadoOferta();
		fetchMotivos();
		fetchComerciales();
		fetchClausulasOferta(ofertas?.id !== undefined ? ofertas?.id : "");
		if (ofertas.licitacionCodigo) {
			fetchLicitacion(ofertas.licitacionCodigo);
		}
	}
		, [fetchContactosClientes, fetchEstudios,
			fetchPresupuestos, fetchTipoOferta,
			fetchEstadoOferta, fetchClausulasOferta,
			fetchMotivos, fetchLibrosEstandar,
			fetchComerciales]);

	const handleRefreshEstudios = (number?: any) => {
		fetchEstudios(ofertas?.id !== undefined ? ofertas?.id : "");
		if (tabIndex === number || number === undefined) {
			handleRefreshOferta(tabIndex);
		} else {
			handleRefreshOferta(number)
		}
	}

	const handleRefreshPresupuestos = () => {
		fetchPresupuestos(ofertas?.id !== undefined ? ofertas?.id : "");
		handleRefreshOferta(tabIndex);
	}

	const handleRefreshClausulas = () => {
		fetchClausulasOferta(ofertas?.id !== undefined ? ofertas?.id : "");
	}

	const handleRefreshLibroEstandar = () => {
		fetchLibrosEstandar(ofertas?.id !== undefined ? ofertas?.id : "");
		handleRefreshOferta(tabIndex);
	}

	const handleContratado = (presupuesto: Presupuesto) => {
		setPresupuestoContratado(presupuesto);
	}

	const handleClickTipoOferta = (b: boolean, e: TipoOferta) => {
		if (ofertas?.id) {
			//solo tenemos que limitar si estamos editando
			switch (dbSavedTipoOferta) {
				case TipoOfertaEnum.Previa://estado previa
					//no hay limitacion
					if (e.id.toString() != TipoOfertaEnum.Estudio.toString() && e.id.toString() != TipoOfertaEnum.Licitacion.toString()) {
						toast.warning("La oferta tiene asociada una licitación, no se puede cambiar el estado");
						return;
					}
					break;
				case TipoOfertaEnum.Licitacion: //Licitacion
					if (e.id.toString() !== TipoOfertaEnum.Interna.toString() && e.id.toString() !== TipoOfertaEnum.Estudio.toString() && e.id.toString() !== TipoOfertaEnum.Licitacion.toString()) {
						//no se puede cambiar
						toast.warning('No se puede cambiar el tipo de una oferta con estado Licitacion');
						return;
					}
					break;
				case TipoOfertaEnum.Ejecucion://ejecucion
					//solo se puede cambiar a Licitacion
					if (e.id.toString() !== TipoOfertaEnum.Licitacion.toString() && e.id.toString() !== TipoOfertaEnum.Ejecucion.toString()) {
						toast.warning('El tipo en ejecución solo se pueda cambiar al tipo de licitación');
						return;
					}
					if (e.id.toString() != TipoOfertaEnum.Estudio.toString() && e.id.toString() != TipoOfertaEnum.Licitacion.toString()) {
						toast.warning("La oferta tiene asociada una licitación, no se puede cambiar el estado");
						return;
					}
					else if (e.id.toString() === TipoOfertaEnum.Ejecucion.toString()) {
						onField("licitacionCodigo").onSelectValue(null);
					}

					setTipoLicitacionSelected(true);
					break;
				case TipoOfertaEnum.Estudio:
					if (e.id.toString() != TipoOfertaEnum.Estudio.toString() && e.id.toString() != TipoOfertaEnum.Licitacion.toString()) {
						toast.warning("La oferta tiene asociada una licitación, no se puede cambiar el estado");
						return;
					}
					break;

			}
		}


		onField("tipoOfertaId").onSelectValue(e.id); //para nuevos
	}

	const actualizarPresupuestoFechaEnvio = (presupuesto: Presupuesto | undefined) => {
		if (presupuestoSelectedOferta && presupuestoSelectedOferta[0] != undefined) { selectPresupuesto(presupuestos.filter(p => p.id == presupuestoSelectedOferta)[0]) }

		if (presupuesto != undefined) {
			const presupuestoUpdate = presupuesto
			presupuestoUpdate.fechaEnvio = presupuesto.fechaGeneracion
			setPresupuestoOfertado(presupuestoUpdate);
		}
	}

	const handleClickEstadoOferta = (b: boolean, e: EstadoOferta) => {
		if (e.id != "3") {
			setOfertada(false);
		}
		if (Number(e.id) < ofertas.estadoOfertaId) {
			toast.info("No es posible volver a un estado anterior ");
			return;
		}

		if (e.id == "3") {
			if (!presupuestos || presupuestos.length == 0) {
				toast.info("Una oferta sin presupuestos asociados no puede cambiar al estado 'Ofertada'");
				return;
			}
			setOfertada(true);
			setOpenPresupuestos(true)
		}

		if (e.id == "5") {
			if (!presupuestos || presupuestos.length == 0) {
				toast.info("Una oferta sin presupuestos asociados no puede cambiar al estado 'Contratada'");
				return;
			}
			setTabIndex(2);
			setEstadoContratada(true);
			setShowConffeti(true);
			if (e.id == "5" && onField("tipoOfertaId").value == 5) {
				onField("tipoOfertaId").onSelectValue(2)
			}
			setTimeout(() => {
				setShowConffeti(false);
			}, 5000);
		}
		if (e.id > "5") {
			setEstadoContratada(false);
		}
		onField("estadoOfertaId").onSelectValue(e.id); //para nuevos
		onField("motivoId").onSelectValue(undefined);
		onField("motivoDescripcion").onSelectValue(undefined);
	}

	const handleDuplicarOferta = () => {
		setOpenModal(true);
	}

	const handleCloseModal = () => {
		setOpenModal(false);
	}

	const handleCloseModalDirty = () => {
		setOpenDirty(false);
		setOpenDirtyTab(false);
	}

	const handleChangeLicitacionCodigo = (e: any) => {
		if (e.target.value.length < 4) {
			return;
		}
		fetchLicitacion(e.target.value, true);
	}

	const debouncedFetchLicitacion = useMemo(() => {
		return debounce((value: number) => handleChangeLicitacionCodigo(value), 1500);
	}, [handleChangeLicitacionCodigo]);


	const selectPresupuesto = (pres: Presupuesto) => {
		setSelectedPresupuesto(pres)
		onField("numeroPresupuesto").onSelectValue(ofertas.codigo + "/" + pres.presupuestoId + "-" + pres.presupuestoVersion);
		if (pres.presupuestoContactos && pres.presupuestoContactos.length > 0) {
			onField("conctactoId").onSelectValue(pres.presupuestoContactos[0].contactoId);
		}

		setOpenPresupuestos(false)
	}

	const deshabilitarFormEstadoOferta = (estado: number): boolean => {
		if (estado === 5 || estado === 8 || estado === 10) {
			return true;
		} else {
			return false;
		}
	}

	const deshabilitarFormEstadoNoEjecutada = (estado: number): boolean => {

		if (estado === 8) {
			return true;
		} else {
			return false;
		}
	}
	const handleCloseModalDriveUrl = () => {
		handleRefreshOferta(tab);
	}

	const addUrlProtocol = (url: string): string => {
		if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
			url = "http://" + url;
		}
		return url;

	}

	const formId = "ofertas-form";
	return (
		<div className="add-edit-body">
			<>
				{
					showConffeti && <Pavaconfeti></Pavaconfeti>
				}
				<div className="single-row">
					<div className="abs-right">
						<CustomButton
							key={`DuplicarOferta`}
							title={'Duplicar'}
							icon="file_copy"
							className={'contact-btn'}
							btnStyle="accept"
							onButtonClick={() => { handleDuplicarOferta() }}
							type="button"
							disabled={readOnly || deshabilitarFormEstadoNoEjecutada(ofertas.estadoOfertaId)}
						/>
					</div>
				</div>
				<FormTemplate
					className={formId}
					formId={formId}
					onSubmit={onSubmitForm}
					saveDisabled={readOnly || (ofertas.estadoOfertaId == EstadoOfertaEnum.Contratada || ofertas.estadoOfertaId == EstadoOfertaEnum.NoEjecutada)}
				>
					<fieldset disabled={readOnly}>
						<div className="row-of-four">
							{
								tipoLicitacionSelected ?
									<>
										<FormField<SingleSuggestionInputFieldProps>
											label="Promotor"
											className={"op-input95"}
											value={
												{
													id: "",
													text: ""
												}

											}
											error={onField("promotorId").error}
											onChange={onField("promotorId").onSelectValue}
											searchCallback={onSearchPromotor}
											component={SingleSuggestionInputField}
											disabled={true}
										/>
									</>
									:
									<FormField<SingleSuggestionInputFieldProps>
										label="Promotor"
										className={"op-input95"}
										value={
											{
												id: licitacion ? licitacion.promotorId : onField("promotorId").value,
												text: licitacion ? licitacion.promotorDescripcion : onField("promotorDescripcion").value
											}

										}
										error={onField("promotorId").error}
										onChange={onField("promotorId").onSelectValue}
										searchCallback={onSearchPromotor}
										component={SingleSuggestionInputField}
										disabled={true}
									/>
							}
							{
								<FormField<SingleSuggestionInputFieldProps>
									label="Cliente"
									className={"op-input95"}
									value={
										{
											id: clienteLicitacion ? clienteLicitacion.id : onField("clienteId").value,
											text: clienteLicitacion ? clienteLicitacion.descripcion : onField("clienteDescripcion").value
										}
									}
									error={onField("clienteId").error}
									onChange={(e) => {
										onField("clienteId").onSelectValue(e);
									}}
									searchCallback={onSearchCliente}
									component={SingleSuggestionInputField}
									disabled={deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
									required
								/>
							}
							<FormInput
								label="Obra"
								value={onField("obraDescripcion").value}
								error={onField("obraDescripcion").error}
								onChange={onField("obraDescripcion").onChangeValue}
								disabled={deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
								required
								idTest="ObraOfertaEdit"
							/>

							<FormField<DropdownProps>
								label="Delegación"
								options={optionsDelegaciones.filter(x => hasRole(USER_ROLES.ADMIN) || +x.id === ofertas.delegacionId).map(
									(element) => {
										return {
											text: element.descripcion,
											id: element.id,
											selected: onField("delegacionId").value === element.id,
											onClick: () => {
												onField("delegacionId").onSelectValue(element.id); fetchComerciales(+element.id);
											}
										}
									})}
								required={true}
								error={onField("delegacionId").error}
								disabled={onField("tipoOfertaId").value == TipoOfertaEnum.Licitacion || deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
								singleSelection={true}
								component={Dropdown}
							/>
						</div>
						<div className="row-of-five">
							<FormInput
								label="Fecha Grabación"
								value={onField("fechaOferta").value}
								error={onField("fechaOferta").error}
								onChange={onField("fechaOferta").onChangeValue}
								disabled={deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
								required
							/>
							<FormInput
								label="Última Modificación"
								value={onField("ultimaModificacion").value}
								error={onField("ultimaModificacion").error}
								onChange={() => { }}
								disabled={true}
							/>
							{
								refreshProvincia ?
									<FormField<SingleSuggestionInputFieldProps>
										label="Provincia"
										className={"op-input95"}
										value={

											{
												id: onField("provinciaId").value,
												text: onField("provinciaDescripcion").value
											}

										}
										error={onField("provinciaId").error}
										onChange={onField("provinciaId").onSelectValue}
										searchCallback={onSearchProvincias}
										component={SingleSuggestionInputField}
										disabled={onField("codigoPostalId").value}
									/> :
									<FormInput
										label="Provincia"
										disabled={true}
										value={onField("provinciaDescripcion").value}
										onChange={(e) => { }}
									/>
							}

							<FormField<SingleSuggestionInputFieldProps>
								label="Municipio"
								className={"op-input95"}
								value={

									{
										id: onField("codigoPostalId").value,
										text: onField("codigoPostalDescripcion").value
									}

								}
								required
								error={onField("codigoPostalId").error}
								onChange={(e) => { onChangeCodigoPostal(e); }}
								searchCallback={onSearchCodigoPostal}
								component={SingleSuggestionInputField}
								disabled={onField("tipoOfertaId").value == TipoOfertaEnum.Licitacion || deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
							/>
							<FormInput
								type="number"
								label="Toneladas"
								value={onField("toneladas").value}
								error={onField("toneladas").error}
								onChange={onField("toneladas").onChangeValue}
								disabled={deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
								required
								idTest="ToneladasOfertaEdit"
							/>
						</div>
						<div className="row-of-four">
							<div>
								<FormInput
									type="number"
									label="Iva"
									value={onField("iva").value}
									error={onField("iva").error}
									onChange={onField("iva").onChangeValue}
									disabled={deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
									required
								/>
							</div>

							<div>
								<FormInput
									type="number"
									label="Beneficio"
									value={onField("beneficio").value}
									error={onField("beneficio").error}
									disabled={deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
									onChange={onField("beneficio").onChangeValue}
								/>
							</div>
							<div>
								<FormInput
									type="number"
									label="Gastos"
									value={onField("gastos").value}
									error={onField("gastos").error}
									disabled={deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
									onChange={onField("gastos").onChangeValue}
								/>
							</div>
							<div>
								<FormInput
									type="number"
									label="Descuento"
									value={onField("descuento").value}
									error={onField("descuento").error}
									disabled={deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
									onChange={onField("descuento").onChangeValue}
								/>
							</div>
						</div>
						<div className="row-of-five">
							{optionsEstadoOferta &&
								<>
									<FormField<DropdownProps>
										label="Estado"
										options={optionsEstadoOferta.filter(p => onField("tipoOfertaId").value == TipoOfertaEnum.Interna.toString() ? true : p.id != EstadoOfertaEnum.Interna.toString()).map(
											(element) => {
												return {
													text: element.descripcion,
													id: element.id,
													selected: onField("estadoOfertaId").value === element.id,
													onClick: () => { handleClickEstadoOferta(true, element); }
												}
											})}
										required
										disabled={ofertas.id == null || deshabilitarFormEstadoOferta(ofertas.estadoOfertaId) || onField("tipoOfertaId").value == TipoOfertaEnum.Interna.toString()}
										singleSelection={true}
										component={Dropdown}
									/>
								</>
							}
							{(+onField("estadoOfertaId").value == 6) && motivos ?
								<>
									<FormField<DropdownProps>
										label="Motivo"
										options={motivos.map(
											(element) => {
												return {
													text: element.descripcion,
													id: element.id,
													selected: onField("motivoId").value === element.id,
													onClick: () => { onField("motivoId").onSelectValue(element.id); }
												}
											})}
										required={+onField("estadoOfertaId").value == 6}
										singleSelection={true}
										component={Dropdown}
										error={onField("motivoId").error}
									/>
								</>
								: (+onField("estadoOfertaId").value > 6 && +onField("tipoOfertaId").value !== TipoOfertaEnum.Interna) &&
								<FormInput
									label="Motivo"
									value={onField("motivoDescripcion").value}
									error={onField("motivoDescripcion").error}
									onChange={onField("motivoDescripcion").onChangeValue}
									idTest="MotivoOfertaEditInput"
								/>
							}
							{(+onField("estadoOfertaId").value == 6) &&
								<FormField<SingleSuggestionInputFieldProps>
									label="Empresa"
									className={"op-input95"}
									value={
										{
											id: onField("clientePerdidaId").value,
											text: onField("clientePerdidaDescripcion").value
										}
									}
									required={+onField("estadoOfertaId").value == 6}
									error={onField("clientePerdidaId").error}
									onChange={(e) => {
										onField("clientePerdidaId").onSelectValue(e);
									}}
									searchCallback={(e) => onSearchContratista(e, "false")}
									component={SingleSuggestionInputField}
									idTest="EmpresaOfertaEdit"
								/>
							}
							{onField("estadoOfertaId").value == EstadoOfertaEnum.Contratada && ofertas.codigoObra &&
									<FormInput
										type="number"
										label="Código Obra"
										value={onField("codigoObra").value}
										error={onField("codigoObra").error}
										disabled={true}
										onChange={onField("codigoObra").onChangeValue}
									/>
							}
							{optionsTipoOferta &&
								<>
									<FormField<DropdownProps>
										label="Tipo Oferta"
										options={optionsTipoOferta
											//comprobamos si tenemos que mostrar el tipo "interna" interna
											.filter(p => ofertaInternaSelectable() ? true : p.id != TipoOfertaEnum.Interna.toString())
											.map(
												(element) => {
													return {
														text: element.descripcion,
														id: element.id,
														selected: onField("tipoOfertaId").value === element.id,
														onClick: () => { handleClickTipoOferta(true, element); }
													}
												})}
										required
										disabled={onField("tipoOfertaId").value !== 5 ? deshabilitarFormEstadoOferta(ofertas.estadoOfertaId) : false}
										singleSelection={true}
										component={Dropdown}
									/>
								</>
							}
							{
								(onField("tipoOfertaId").value == TipoOfertaEnum.Licitacion || onField("tipoOfertaId").value == TipoOfertaEnum.Interna) &&
								<FormInput
									label="Licitación"
									value={onField("licitacionCodigo").value}
									error={onField("licitacionCodigo").error}
									onChange={(e) => { onField("licitacionCodigo").onChangeValue(e); debouncedFetchLicitacion(e); }}
									type="number"
									disabled={deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
								/>
							}
							{optionsComerciales &&
								<FormField<DropdownProps>
									label="Comercial"
									options={optionsComerciales.map((element) => {
										return {
											text: element.descripcion,
											id: element.id.toString(),
											selected: onField("comercialId").value === element.id,
											onClick: () => {
												onField("comercialId").onSelectValue(element.id);
												handleClickComercial(element);
											}
										}
									})}
									required={false}
									disabled={false}
									singleSelection={true}
									component={Dropdown}
								/>
							}
							<div className="google-drive">
								<div className="row-of-two">
									<IconButton className="float-none" icon='add_to_drive' onClick={() => {
										setOpenModalDriveUrl(true);
									}} />
									<a target="_blank" href={addUrlProtocol(ofertas.driveUrl)}> {ofertas.driveUrlDescripcion}</a>
								</div>
							</div>
						</div>
						<div className="single-row">
							<CustomTextInput
								label="Observaciones"
								name="observaciones"
								value={onField("observaciones").value}
								onChange={onField("observaciones").onChangeValue}
								resizable />
						</div>
					</fieldset>
					<div className="tabs-container">
						<Tabs selectedIndex={tabIndex} onSelect={(index) => changeTab(index)}>
							<TabList>
								<Tab>Seguimiento Ofertas{dirtySeguimientoOfertas ? <label title={t("existenCambiosSinGuardar")}><Icon icon="warning" /></label> : <></>}</Tab>
								<Tab>Estudios</Tab>
								<Tab>Presupuestos</Tab>
								<Tab>Cláusulas</Tab>
								{pathName && ofertas.estadoOfertaId == 5 &&
									<Tab>Libro Estándar</Tab>
								}

								{ofertas && ofertas.estadoOfertaId === 5 && pathName &&
									//  presupuestos.filter(p=>p.contratado).length > 0 &&  
									<Tab> Contrato</Tab>
								}

							</TabList>
							<TabPanel>
								{pathName && contactosClientes &&
									<SeguimientoOfertaView type='add'
										handleOnSubmit={() => { }}
										contactosCliente={contactosClientes}
										title=""
										idPadre={pathName}
										estadoOfertaId={ofertas.estadoOfertaId}
										clienteId={onField("clienteId").value}
										hadleDirtyForm={handleDirtySeguimientoOfertas}
										readOnly={readOnly}
									>
									</SeguimientoOfertaView>
								}
							</TabPanel>
							<TabPanel>
								{pathName && estudios ?
									<EstudiosView
										title=""
										estudios={estudios}
										idPadre={pathName}
										ofertaId={ofertas?.codigo}
										oferta={ofertas}
										handleRefreshEstudios={handleRefreshEstudios}
										disabled={deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
										readOnly={readOnly}
									>
									</EstudiosView> : <span>Cargando</span>
								}
							</TabPanel>
							<TabPanel>
								{pathName && presupuestos && ofertas &&
									<PresupuestosView
										title=""
										presupuestos={presupuestos}
										idPadre={pathName}
										oferta={ofertas}
										handleRefreshPresupuestos={handleRefreshPresupuestos}
										handleContratado={handleContratado}
										contratada={estadoContratada}
										disabled={readOnly || deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
										isOfertada={isOfertada}
									>
									</PresupuestosView>
								}
							</TabPanel>
							<TabPanel>
								{pathName && clausulasOferta &&
									<ClausulasOfertaView
										title=""
										clausulasOferta={clausulasOferta}
										ofertaId={pathName}
										handleRefreshClausulas={handleRefreshClausulas}
										disabled={readOnly || deshabilitarFormEstadoOferta(ofertas.estadoOfertaId)}
										delegacion={optionsDelegaciones.find(x => x.id === onField("delegacionId").value)?.descripcion}
										clausulas={clausulas}
									>
									</ClausulasOfertaView>
								}
							</TabPanel>
							<TabPanel>
								{pathName && refreshLE &&
									<LibroEstandarView
										oferta={ofertas}
										librosEstandarProp={librosEstandar}
										disabled={readOnly}
										handleRefreshLibroEstandar={handleRefreshLibroEstandar}
									>
									</LibroEstandarView>
								}
							</TabPanel>
							<TabPanel>
								{ofertas && ofertas.estadoOfertaId === 5 && pathName &&
									//  presupuestos.filter(p=>p.contratado).length > 0 &&  
									<ContratosView
										oferta={ofertas}
										idPadre={pathName}
										presupuestoContratado={presupuestos.filter(p => p.contratado).length > 0 ? presupuestos.filter(p => p.contratado)[0] : undefined}
										title=""
										updateData={updateCloseModalContrato}
										disabled={readOnly}
									>
									</ContratosView>
								}
							</TabPanel>
						</Tabs>
					</div>
				</FormTemplate>
				{(openModalDriveUrl && ofertas) &&
					<DriveUrlModal
						ofertaId={ofertas.id}
						open={openModalDriveUrl}
						children={
							<>

							</>
						}
						handleCloseModal={handleCloseModalDriveUrl}
						url_old={ofertas.driveUrl}
						descripcion_old={ofertas.driveUrlDescripcion}
					></DriveUrlModal>}
				{(openModal && ofertas) &&
					<ClonarOfertasModalSection
						opened={openModal}
						ofertaId={ofertas?.id}
						estudios={estudios}
						clausulas={clausulasOferta ?? []}
						handleCloseModal={handleCloseModal}
						delegacion={optionsDelegaciones.find(x => x.id === onField("delegacionId").value)?.descripcion}>
					</ClonarOfertasModalSection>}
				{(openDirty || openDirtyTab) &&
					<DirtyModal open={(openDirty || openDirtyTab)} handleCloseModal={handleCloseModalDirty}>
						{
							openDirty ?
								<CustomButton
									key={`confirmDeleteModal`}
									title={'Borrar'}
									className={'btn-primary'}
									btnStyle="delete"
									onButtonClick={() => { onSubmit(dataForm); }}
									text={t("si")}
								/>
								:
								<CustomButton
									key={`confirmDeleteModal`}
									title={'Borrar'}
									className={'btn-primary'}
									btnStyle="delete"
									onButtonClick={() => { setTabIndex(tabIndex2Change); setOpenDirtyTab(false); handleDirtySeguimientoOfertas(false); }}
									text={t("si")}
								/>
						}
					</DirtyModal>}
				{openCambioEstados &&
					<div>
						<ModalPage
							className="filters-modal modal-l"
							handleCloseModal={() => { setOpenCambioEstados(false) }}
							opened={openCambioEstados}
							modalTitle="¿Estás seguro de continuar con dicho cambio?"
							modalSubtitle="Las otras ofertas de esta oportunidad pueden verse afectadas"
							fields={
								<div>
									<CustomButton
										key={`confirmDeleteModal`}
										className={'btn-primary'}
										onButtonClick={() => { setEstadoContratada(true); setOpenCambioEstados(false); }}
										text={t("si")}
									/>
									<CustomButton
										key={`confirmDeleteModal`}
										title={'Borrar'}
										className={'btn-primary'}
										btnStyle="delete"
										onButtonClick={() => { setOpenCambioEstados(false); onField("estadoOfertaId").onSelectValue(ofertas.estadoOfertaId); setEstadoContratada(false); }}
										text={t("No")}
									/>

								</div>
							}
						>
						</ModalPage>
					</div>
				}

				{openPresupuestos &&
					<div>
						<ModalPage
							className="filters-modal modal-l"

							handleCloseModal={() => { setOpenPresupuestos(false) }}
							opened={openPresupuestos}
							modalTitle="Seleccione el presupuesto ofertado"
							fields=
							{
								<>
									{
										presupuestos.map((item) => {
											return (
												<>
													<div key={`estudio-${ofertas.codigo}-${item.presupuestoId}-${item.presupuestoVersion}`} className="inline-grid-item">
														<div className="inline-grid-checkbox" style={{ width: "10px", display: "inline-block" }}>
															<CustomCheckInput
																checked={item.id === presupuestoSelectedOferta}
																icon={item.id === presupuestoSelectedOferta ? "check" : undefined}
																onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPresupuestoSelectedOferta(item.id) }/*handleCheck(e.target.checked, estudio)*/}
																disabled={false} />
														</div>
													</div>
													<div className="inline-grid-checkbox" style={{ marginLeft: "35px" }}>
														<CustomInput
															key={`$estudioId-`}
															value={ofertas.codigo + "/" + item.presupuestoId + "-" + item.presupuestoVersion}
															className={'platform-input secondary-input'}
															formClassName={"secondary-input"}
															onChange={(e) => { }}
															disabled={false}
														/>
													</div>
												</>
											)
										})
									}
									<div style={{ marginTop: "25px", marginBottom: "10px", float: "right" }}>
										<CustomButton
											key={`confirSegModal`}
											className={'btn-primary'}
											onButtonClick={() => { actualizarPresupuestoFechaEnvio(presupuestos.find(p => p.id === presupuestoSelectedOferta)) }}
											text={"Aceptar"}
										/>
									</div>
								</>
							}
						>
						</ModalPage>
					</div>
				}
			</>
		</div>
	)
}


export default OfertasFormTemplateEdit;