

interface EstudioDetalleAvanzadoMaterialEntity {
	id?: string;
	codigoArticulo:number;
	materialId:string|undefined;
	materialDescripcion?:string;
	densidad:string;
	espesor:string;
	superficie:string;
	volumen: number;
	toneladas:string;
	baremo:string;
	baremoOriginal?:string;
	riego:number;
	coeficiente: number;
	descripcionDetalle?: string;
	orden:number;
	fechaSincronizacion?: Date;
	totalizarGrupo?: number;
}


export default class EstudioDetalleAvanzadoMaterial implements EstudioDetalleAvanzadoMaterialEntity {
	id?: string;
	codigoArticulo:number;
	materialId:string|undefined;
	materialDescripcion?:string;
	densidad:string;
	espesor:string;
	superficie:string;
	volumen: number;
	toneladas:string;
	baremo:string;
	baremoOriginal?:string;
	riego:number;
	coeficiente: number;
	descripcionDetalle?: string;
	orden:number;
	totalEurosM2?:number;
	fechaSincronizacion?: Date;
	totalizarGrupo?: number;

	constructor(entity: EstudioDetalleAvanzadoMaterialEntity) {
		this.id = entity.id;
		this.codigoArticulo = entity.codigoArticulo;
		this.materialId=entity.materialId;
		this.materialDescripcion=entity.materialDescripcion;
		this.densidad=entity.densidad;
		this.espesor=entity.espesor;
		this.superficie=entity.superficie;
		this.toneladas=entity.toneladas;
		this.baremo=entity.baremo;
		this.baremoOriginal=entity.baremoOriginal;
		this.riego=entity.riego;
		this.coeficiente=entity.coeficiente;
		this.descripcionDetalle= entity.descripcionDetalle;
		this.orden = entity.orden;
		this.volumen = entity.volumen;
		this.fechaSincronizacion = entity.fechaSincronizacion;
		this.totalizarGrupo = entity.totalizarGrupo;
	}

	public static toArray(entities: EstudioDetalleAvanzadoMaterialEntity[]): EstudioDetalleAvanzadoMaterial[] {
		return entities.map(entity => { return new EstudioDetalleAvanzadoMaterial(entity) });
	}
}

export interface EstudioDetalleAvanzadoMaterialDto extends EstudioDetalleAvanzadoMaterial {
	errors: string[]
};