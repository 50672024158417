import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { EstudioApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Estudio from "../../domain/model/Estudio";

export default class EstudioRequestListByFilter extends ApiRequest<any, BaseResponse<Estudio[]>> {
	constructor(id: number, delegacionId: number, token: string) {
		super(
			"POST",
			EstudioApiEndpoints.CON_FILTRO,
			{ Authorization: token },
			{
				filter: {
					CodigoOferta: id,
					DelegacionId: delegacionId
				}
			}
		)
	}
}