import UnidadMedida from "../../../../domain/model/UnidadMedida";
import EstudioDetalleAvanzado from "./EstudioDetalleAvanzado";
import { UnidadObraCalculoAvanzado } from "./UnidadesObra";


interface EstudioDetalleEntity {
	id:string, 
	estudioId:string,
	unidadObraId:string,
	unidadObra?:UnidadObraCalculoAvanzado,
	unidadMedida:UnidadMedida,
	unidadMedidaId:string
	descripcion:string,
	medicion:number,
	volumen?: number;
	precioCoste:number,
	importeCoste:number,
	totalCoste?:number
	coeficiente:number,
	precioVenta:number,
	importeVenta:number
	totalVenta?:number
	estudioDetalleAvanzadoId?: string;
	estudioDetalleAvanzado?:EstudioDetalleAvanzado;
	orden: number;
	ordenDetalle: number;
	totalizarGrupo?: number;
	notas?:string;
}

export default class EstudioDetalle implements EstudioDetalleEntity {
	id:string;
	estudioId:string;
	unidadObraId:string;
	unidadObra?:UnidadObraCalculoAvanzado;
	unidadMedida:UnidadMedida;
	unidadMedidaId:string;
	descripcion:string;
	medicion:number;
	volumen?: number;
	precioCoste:number;
	importeCoste:number;
	totalCoste?:number;
	coeficiente:number;
	precioVenta:number;
	importeVenta:number;
	totalVenta?:number;
	estudioDetalleAvanzadoId?: string;
	estudioDetalleAvanzado?: EstudioDetalleAvanzado;
	orden: number;
	ordenDetalle: number;
	totalizarGrupo?: number;
	notas?:string;

	constructor(entity: EstudioDetalleEntity) {
		this.id = entity.id;
		this.estudioId=entity.estudioId;
		this.unidadObraId=entity.unidadObraId;
		this.unidadObra=entity.unidadObra;
		this.unidadMedida=entity.unidadMedida;
		this.unidadMedidaId=entity.unidadMedidaId;
		this.descripcion=entity.descripcion;
		this.medicion=entity.medicion;
		this.volumen=entity.volumen;
		this.precioCoste=entity.precioCoste;
		this.importeCoste=entity.importeCoste;
		this.totalCoste=entity.totalCoste;
		this.coeficiente=entity.coeficiente;
		this.precioVenta=entity.precioVenta;
		this.importeVenta=entity.importeVenta;
		this.totalVenta=entity.totalVenta;
		this.estudioDetalleAvanzadoId = entity.estudioDetalleAvanzadoId;
		this.estudioDetalleAvanzado=entity.estudioDetalleAvanzado;
		this.orden=entity.orden;
		this.ordenDetalle=entity.ordenDetalle;
		this.totalizarGrupo=entity.totalizarGrupo;
		this.notas=entity.notas;
	}

	public static toArray(entities: EstudioDetalleEntity[]): EstudioDetalle[] {
		return entities.map(entity => { return new EstudioDetalle(entity) });
	}
}

export interface EstudioDetalleDto extends EstudioDetalle {
	errors: string[]
};

export interface EstudioDetalleCreationDto extends EstudioDetalle {
	errors: string[]
};
export type EstudioDetalleUpdateDto = EstudioDetalle;