import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  GlobalContextType,
} from "../../../context/Global.Context";
import { BaseFilter } from "../../../domain/BaseFilter";
import useColumnasGrid from "../../../hooks/useColumnasGrid";
import { AuthContext } from "../../Login/AuthContextProvider";
import useFilters from "../../Oportunidades/hooks/useFilters/useFilters";
import { OfertasFilter } from "../domain/model/Ofertas";
import OfertasRepository from "../domain/OfertasRepository";
import useOfertasPost from "../hooks/useOfertasPost";
import useOfertasSumaToneladasPost from "../hooks/useOfertasSumaToneladasPost";
import usePomotoresOfertasTags from "../hooks/useOfertasTags";
import OfertasApiRepository from "../infraestructure/api/Ofertas.ApiRepository";
import {
  OfertasFilterContext,
  OfertasFilterState,
  OfertasPagination,
  OfertasSearchHandler,
  OfertasTableData,
  OfertasTagHandler,
  OfertasTags,
} from "./Ofertas.Context";

const OfertasProvider: React.FC<{}> = ({ children }) => {
  const { columns } = useColumnasGrid("BACKENDOPORTUNIDADES", "OFERTA");
  const defaultSorting = [{ field: "ultimaModificacion", desc: true }];
  const { globalState, updateGlobalState } = useContext(
    GlobalContext
  ) as GlobalContextType;
  const globalFilters = globalState?.ofertas?.filters;
  const {
    filter,
    sorting,
    onSearchField,
    onPageBackwards,
    onPageSelect,
    onSetMaxCount,
    onSelectField,
    onRangeField,
    onDateField,
    onReset,
    onSort,
  } = useFilters<OfertasFilter>({
    sorting: defaultSorting,
    filtering: {
      filter: globalFilters ?? {},
      maxResultCount: globalState?.ofertas?.pagination?.maxResultCount ?? 10,
      skipCount: globalState?.ofertas?.pagination?.skipCount ?? 0,
      sortingCriteria: globalState?.ofertas?.order?.sortingCriteria
        ? globalState?.ofertas?.order?.sortingCriteria +
          globalState?.ofertas?.order?.desc
        : "",
    },
  });

  const maxResultCount = filter.maxResultCount;
  const [check, setcheck]=useState<boolean>(false);

  const { getDelegacion } = useContext(AuthContext);
  const [updatedFilter, setUpdatedFilter] = useState<BaseFilter<OfertasFilter>>(
    {
      filter: {
                ...filter.filter, delegacionIds: globalState?.ofertas?.filters?.isChecked ? [getDelegacion().id] : globalState?.ofertas?.filters?.delegacionIds
              }, maxResultCount, skipCount: filter.skipCount, sortingCriteria: sorting[0].field+" "+(sorting[0].desc?"DESC":"")
            
    } as BaseFilter<OfertasFilter>);

  const { getToken } = useContext(AuthContext);

  const downloadExcel = () => {
    const repo: OfertasRepository = new OfertasApiRepository(getToken());
    repo.fetchOfertasDownloadExcel(updatedFilter).then((data) => {
      window.open(data);
    });
  };
  
  useEffect(() => {
    const isChecked = globalState?.ofertas?.filters?.isChecked;
    const skipCount = filter.skipCount;
    const filterId = globalState?.ofertas?.filters?.delegacionIds as Array<number>;
    if(check!==undefined)
    {
      setcheck( true)
    }
    else{
      //nothing
    }

    if (isChecked || (filterId && filterId.length==0)) {
      setUpdatedFilter(
        {filter: {...filter.filter, delegacionIds: [getDelegacion().id]}, maxResultCount, skipCount, sortingCriteria: sorting[0].field+" "+(sorting[0].desc?"DESC":"")} as BaseFilter<OfertasFilter>)
    } else {
      setUpdatedFilter(
        {filter: {...filter.filter, filterId}, maxResultCount, skipCount, sortingCriteria: sorting[0].field+" "+(sorting[0].desc?"DESC":"")} as BaseFilter<OfertasFilter>)
    }
  }, [filter, getDelegacion, globalState?.ofertas?.filters?.isChecked, maxResultCount]);

  useEffect(() => {
		
		
    
    updateGlobalState("ofertas", {
				filters: {
				  ...globalState["ofertas"]?.filters,
				  isChecked: globalState["ofertas"]?.filters ? globalState["ofertas"]?.filters.isChecked : true,
				},
				pagination: {
				  skipCount: 0,
				},
        order: {
          sortingCriteria: filter.sortingCriteria,
          desc: true
        }
			  });
      }
	  , [])

  const data = useOfertasPost(updatedFilter);

  const sumaToneladas = useOfertasSumaToneladasPost(updatedFilter);

  const { tags, updateTags } = usePomotoresOfertasTags();

  return (
    <OfertasTableData.Provider
      value={{ columns, data, sumaToneladas, downloadExcel }}
    >
      <OfertasFilterState.Provider value={{ filter }}>
        <OfertasSearchHandler.Provider value={{ onSearchField }}>
          <OfertasTags.Provider value={{ tags }}>
            <OfertasTagHandler.Provider value={{ onFilter: updateTags }}>
              <OfertasPagination.Provider
                value={{
                  sorting,
                  maxResultCount,
                  onPageBackwards,
                  onPageSelect,
                  onSetMaxCount,
                  onSort,
                }}
              >
                <OfertasFilterContext.Provider
                  value={{
                    onSelectField,
                    onSearchField,
                    onRangeField,
                    onDateField,
                    onReset,
                  }}
                >
                  {children}
                </OfertasFilterContext.Provider>
              </OfertasPagination.Provider>
            </OfertasTagHandler.Provider>
          </OfertasTags.Provider>
        </OfertasSearchHandler.Provider>
      </OfertasFilterState.Provider>
    </OfertasTableData.Provider>
  );
};

export default OfertasProvider;
