import { useCallback, useContext, useState } from "react";
import ModalPage from "../../../../../components/ui/atoms/ModalPage/ModalPage";
import { AuthContext } from "../../../../Login/AuthContextProvider";
import "./ClonarOfertas.ModalSection.scss";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import IconButton from "../../../../../components/ui/atoms/Buttons/IconButton";
import Dropdown, { DropdownProps } from '../../../../../components/ui/atoms/Dropdown/Dropdown';
import SingleSuggestionInputField, { SingleSuggestionInputFieldProps } from "../../../../../components/ui/atoms/SingleSuggestionInput/SingleSuggestionInput.Field";
import FormField from "../../../../../components/ui/molecules/Form-field/FormField";
import { useLoading } from "../../../../../context/Loading.Context";
import { CustomButton } from "../../../../../shared/components/Buttons/CustomButton";
import ClausulaOferta from "../../../../ClausulasOferta/domain/model/ClausulaOferta";
import ContactosRepository from "../../../../Clientes/domain/ContactosRepository";
import ContactosCliente from "../../../../Clientes/domain/model/ContactosCliente";
import { default as ContactosClientesApiRepository } from "../../../../Clientes/infraestructure/api/ContactosClientes.ApiRepository";
import ContactoModalCreateSection from "../../../../Contactos/ContactoModalCreateSection";
import Estudio from "../../../../Estudios/domain/model/Estudio";
import ClienteDetalleRepository from "../../../../Oportunidades/domain/ClienteDetalleRepository";
import ClienteRepository from "../../../../Oportunidades/domain/ClienteRepository";
import ClienteApiRepository from "../../../../Oportunidades/infraestructure/api/Cliente.ApiRepository";
import ClienteDetalleApiRepository from "../../../../Oportunidades/infraestructure/api/ClienteDetalle.ApiRepository";
import PresupuestoApiRepository from "../../../../Presupuestos/Infraestructure/api/Presupuesto.ApiRepository";
import PresupuestoRepository from "../../../../Presupuestos/domain/PresupuestoRepository";
import OfertasRepository from "../../../domain/OfertasRepository";
import ClonarOfertas, { ClienteContactoOferta } from "../../../domain/model/ClonarOfertas";
import { CopiarOfertasResponse } from "../../../domain/model/Ofertas";
import OfertasApiRepository from "../../../infraestructure/api/Ofertas.ApiRepository";

//TOFIX: Select descripcion - default value
export interface ClonarOfertasModalSectionProps {
	opened?: boolean,
	ofertaId: string,
	estudios: Estudio[],
	clausulas: ClausulaOferta[],
	handleCloseModal: () => void,
	delegacion: string | undefined
}

const ClonarOfertasModalSection: React.FC<ClonarOfertasModalSectionProps> = ({
		opened,
		ofertaId,
		estudios,
		clausulas,
		handleCloseModal,
		delegacion
	}
) => {
	const { t } = useTranslation<['main']>(['main']);
	const { getToken } = useContext(AuthContext);
	const [clienteId, setClienteId] = useState<number>();
	const [contactos, setContactos] = useState<ContactosCliente[]>([]);
	const [selectedContacto, setSelectedContacto] = useState<ContactosCliente>();
	const [clienteContactoOferta, setClienteContactoOferta] = useState<ClienteContactoOferta[]>([]);
	const [borradorPdfUrl, setBorradorPdfUrl] = useState<string>();
	const [resultadoClonacion, setResultadoClonacion] = useState<CopiarOfertasResponse[]>([]);
	const [clausulasDropdown, setClausulasDropdown] = useState<ClausulaOferta[]>([]);
	const [clausulasList, setClausulasList] = useState<ClausulaOferta[]>(clausulas);
	const [selectedClausulas, setSelectedClausulas] = useState<string[]>((clausulas?.map(item => { return item.id! })));
	const [selectedClausula, setSelectedClausula] = useState<ClausulaOferta>();
	const [estudiosDropdown, setEstudiosDropdown] = useState<Estudio[]>([]);
	const [estudiosList, setEstudiosList] = useState<Estudio[]>(estudios);
	const [selectedEstudios, setSelectedEstudios] = useState<string[]>(estudios.map(item => { return item.id }));
	const [selectedEstudio, setSelectedEstudio] = useState<Estudio>();
	const [porOpciones, setPorOpciones] = useState<boolean>(false);
	const [desscripcionEmail, setDescripcionEmail] = useState<string>("Estimado cliente, a continuación, le ofertamos precios para la obra del epígrafe.");
	const { setLoading, isLoading } = useLoading();

	const fetchContactos = useCallback((id?: string) => {
		const repo: ContactosRepository = new ContactosClientesApiRepository(getToken());
		return repo.getByClienteId(+(clienteId ?? id ?? 0))
			.then((items) => {
				setContactos(items);
				if (id) {
					setSelectedContacto(items.find(x => x.id == id));
				}
			});
	}
		, [getToken, clienteId]);

	const onSearchCliente = useCallback(
		(search: string) => {
			const repo: ClienteRepository = new ClienteApiRepository(getToken());
			setSelectedContacto(undefined)
			return repo.searchCliente({
				filter: { descripcion: search }
			});
		},
		[]);

	const addClienteContactoOferta = () => {
		if (clienteContactoOferta.find(p => p.clienteId == clienteId)) {
			toast.warning(t("clonarOfertaErrorAñadirCliente"));
		}
		else {
			const repo: ClienteDetalleRepository = new ClienteDetalleApiRepository(getToken());

			repo.getById(+(clienteId ?? 0)).then((response) => {
				setClienteContactoOferta([...clienteContactoOferta, { clienteId, clienteDescripcion: response.descripcion, contactoId: selectedContacto?.id, contactoDescripcion: selectedContacto?.personaContacto ?? "" }]);
			})
		}
	}

	const borradorPDF = () => {
		setLoading(true);
		const repo: PresupuestoRepository = new PresupuestoApiRepository(getToken());
		repo.borradorPresupuesto(new ClonarOfertas(ofertaId, selectedEstudios, selectedClausulas, true, porOpciones, clienteContactoOferta)).then((response) => {
			setBorradorPdfUrl(response.replace("http://", "https://"));
		}).finally(() => {
			setLoading(false);
		});
	}

	const onChangeCliente = (id: string[]) => {
		if (id && id[0] && id[0] != "") {
			setClienteId(+id[0]);
			fetchContactos(id[0]);
		}
	}

	const clonarOfertas = async () => {
		const repo: OfertasRepository = new OfertasApiRepository(getToken());
		setLoading(true);
		repo.cloneOfertas(new ClonarOfertas(ofertaId, selectedEstudios, selectedClausulas, false, porOpciones, clienteContactoOferta)).then((response) => {
			if (response) {
				setResultadoClonacion(response);
				toast.success(t("ofertaClonadaCorrectamente"));
			}
		}).finally(() => { setLoading(false); });
	}

	const clonarEnviasOfertas = async () => {
		const repo: OfertasRepository = new OfertasApiRepository(getToken());
		const presupuestoRepo: PresupuestoRepository = new PresupuestoApiRepository(getToken());
		setLoading(true);

		repo.cloneOfertas(new ClonarOfertas(ofertaId, selectedEstudios, selectedClausulas, true, porOpciones, clienteContactoOferta)).then((response) => {
			if (response) {

				setResultadoClonacion(response);
				toast.success(t("ofertaClonadaCorrectamente"));

				response.forEach((item) => {
					presupuestoRepo.enviar(item.presupuestoId, item.ofertaId, desscripcionEmail).then((resp) => {
						toast.success(t("presupuestoEnvidoCorrectamente") + item.contactoStr);
					})
						.catch((error) => {
							toast.error(t("errorEnviarPresupuesto") + item.contactoStr);
						});
				})
			}
		}).finally(() => { setLoading(false); });
	}

	const [openContactoModal, setOpenContactoModal] = useState<boolean>(false);
	const [loadedNewContacto, setLoadedNewContacto] = useState<boolean>(false);

	const handleNewContacto = () => {
		if (clienteId) {
			setOpenContactoModal(!openContactoModal);
			setLoadedNewContacto(!loadedNewContacto);
			setClienteId(clienteId);
		}
		else {
			toast.warning("Se tiene que seleccionar primero un cliente");
		}
	}

	const handleCreateContact = (id: string) => {
		setOpenContactoModal(false);
		fetchContactos(id);
	}

	const handleClickContactos = (contacto: ContactosCliente) => {
		if (selectedContacto?.id == contacto.id) {
			setSelectedContacto(undefined);
		} else {
			setSelectedContacto(contacto);
		}
	}

	const borrarContacto = (clienteContactoOfertaBorrar: ClienteContactoOferta) => {
		if (clienteContactoOfertaBorrar.contactoId) {
			setClienteContactoOferta(clienteContactoOferta.filter(item => item.contactoId != clienteContactoOfertaBorrar.contactoId && item.clienteId != clienteContactoOfertaBorrar.clienteId));
		} else {
			setClienteContactoOferta(clienteContactoOferta.filter(item => item.clienteId != clienteContactoOfertaBorrar.clienteId));
		}
	}

	const disabledButtons = () => {
		return !clienteContactoOferta || (clienteContactoOferta && clienteContactoOferta.length < 1);
	}

	const disabledButtonsEnviar = () => {
		return !clienteContactoOferta || (clienteContactoOferta && clienteContactoOferta.length < 1) || (clienteContactoOferta && clienteContactoOferta.filter(item => item.contactoId).length == 0);
	}

	const handleClickEstudios = (estudio: Estudio) => {
		setEstudiosList([...estudiosList, estudio]);
		setEstudiosDropdown(estudiosDropdown.filter(x => x.estudioId != estudio.estudioId));
		setSelectedEstudios([...selectedEstudios, estudio.id!]);
		setSelectedEstudio(estudio);
	}

	const borrarEstudio = (est: Estudio) => {
		setEstudiosDropdown([...estudiosDropdown, estudios.find((x) => x.id == est.id)!]);
		setEstudiosList(estudiosList.filter((x) => x.id !== est.id)!);
		setSelectedEstudios(selectedEstudios.filter(item => item && item != est.id));
		toast.success(`Estudio eliminado correctamente`);
	}

	const handleClickClausulas = (clausula: ClausulaOferta) => {
		setClausulasList([...clausulasList, clausula].sort((a, b) => a.orden - b.orden));
		setClausulasDropdown(clausulasDropdown.filter(x => x.id !== clausula.id));
		setSelectedClausulas([...selectedClausulas, clausula.id!]);
		setSelectedClausula(clausula);
	}

	const borrarClausula = (cOferta: ClausulaOferta) => {
		setClausulasDropdown([...clausulasDropdown, clausulas.find((x) => x.id == cOferta.id)!]);
		setClausulasList(clausulasList.filter((x) => x.id !== cOferta.id)!);
		setSelectedClausulas(selectedClausulas.filter(item => item && item != cOferta.id));
		toast.success(`Cláusula eliminada correctamente`);
	}

	const handlePorOpciones = async () => {
		setPorOpciones(!porOpciones)
	};

	return (
		<div>
			<ModalPage
				modalTitle="Clonar Ofertas"
				handleCloseModal={handleCloseModal}
				bodyClassName='--large'
				opened={opened}
				fields={
					<>
						<div className='double-col-grid'>
							{
								estudios &&
								<FormField<DropdownProps>
									label="Estudios"
									options={estudiosDropdown.map(
										(element) => {
											return {
												text: element.estudioId?.toString(),
												id: element.estudioId?.toString(),
												selected: selectedEstudio?.id === element.id,
												onClick: () => { handleClickEstudios(element); }
											}
										})}
									required
									disabled={estudios.length == 0}
									singleSelection={true}
									component={Dropdown}
								/>
							}
							{
								clausulas &&
								<FormField<DropdownProps>
									label="Cláusulas"
									options={clausulasDropdown.map(
										(element) => {
											return {
												text: element.clausula?.nombre ?? element.nombre,
												id: element.id,
												selected: selectedClausula?.id === element.id,
												onClick: () => { handleClickClausulas(element); }
											}
										})}
									required
									disabled={clausulas.length == 0}
									singleSelection={true}
									component={Dropdown}
								/>
							}

							<div>
								<table className="mini-table">
									<thead>
										<tr className='table-header'>
											<th>Id</th>
											<th>Descripción</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{estudiosList?.map((item: Estudio, index: number) => (
											<tr key={`${index}-${item.id}`}>
												<td>{item.estudioId}</td>
												<td>{item.descripcion}</td>
												<td>
													<IconButton
														icon="delete"
														onClick={() => { borrarEstudio(item) }}
													/>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>

							<div>
								<table className="mini-table">
									<thead>
										<tr className='table-header'>
											<th>Nombre de la cláusula</th>
											<th>Delegación</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{clausulasList?.map((item: ClausulaOferta, index: number) => (
											<tr key={`${index}-${item.id}`}>
												<td>{item.clausula?.nombre ?? item.nombre}</td>
												<td>{item.clausula?.delegacionNombre ? item.clausula?.delegacionNombre : delegacion}</td>
												<td>
													<IconButton
														disabled={item.clausulaId !== null && !item.clausula?.delegacionId}
														icon="delete"
														onClick={() => { borrarClausula(item) }}
													/>
												</td>

											</tr>
										))}
									</tbody>
								</table>
							</div>

							<div className="import-study">
								<label>Presupuesto por opciones</label>
								<input
									onChange={handlePorOpciones}
									type="checkbox"
								/>
							</div>

							<div>
								&nbsp;
							</div>
							
							<div>
								<FormField<SingleSuggestionInputFieldProps>
									label="Cliente"
									value= {{
											id: "",
											text: ""
										}}
									onChange={(e) => {
										onChangeCliente(e)
									}}
									searchCallback={onSearchCliente}
									component={SingleSuggestionInputField}
								/>
							</div>

							<div className="agregar-contacto">
								{
									contactos &&
									<>
										<FormField<DropdownProps>
											label="Contactos"
											options={contactos.map(
												(element) => {
													return {
														text: element.personaContacto,
														id: element.id,
														selected: selectedContacto?.id == element.id ? true : false,
														onClick: () => { handleClickContactos(element); }
													}
												})}
											createNewItem={true}
											titleNewItem='Nuevo Contacto'
											handleNewItem={handleNewContacto}
											disabled={false}
											singleSelection
											component={Dropdown} />

										<CustomButton
											key={`clonarOferta`}
											title={'Agregar'}
											className={''}
											btnStyle="accept"
											disabled={!(!!clienteId)}
											onButtonClick={() => { addClienteContactoOferta(); }}
											type="button"
											text="Agregar Cliente Contacto" />
									</>
								}
								{openContactoModal &&
									<ContactoModalCreateSection
										clienteId={clienteId?.toString()!}
										handleCloseModal={handleCreateContact}
									/>
								}
							</div>
						</div>
						<table className="mini-table">
							<thead>
								<tr className='table-header'>
									<th>Cliente</th>
									<th>Contacto</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{clienteContactoOferta && clienteContactoOferta.map((item: ClienteContactoOferta, index: number) => (
									<tr key={index}>
										<td>{item.clienteDescripcion}</td>
										<td>{item.contactoDescripcion}</td>
										<td>
											<IconButton
												icon="delete"
												onClick={() => { borrarContacto(item) }}
											/>
										</td>

									</tr>
								))}
							</tbody>
						</table>
						<div>
							<div className="content-fill">
								{
									borradorPdfUrl &&
									<embed width={"100%"} height={"400px"} src={borradorPdfUrl} ></embed>
								}
							</div>
							<div className="content-fill">
								{
									resultadoClonacion &&
									resultadoClonacion.length > 0 &&
									<>
										<h2>Resultado de la  Clonación</h2>
										{
											resultadoClonacion.map((resultado) => {
												return <>
													<label className="contact-name">{resultado.contactoStr}</label>
													<label> ({resultado.clienteStr})</label>
													<a href={`/ofertas/edicion/${resultado.ofertaId}`} about="blank"> OFERTA</a>
												</>
											})
										}
									</>
								}
							</div>
							<div className="buttons-right">
								<CustomButton
									key={`crearBorrador`}
									title={'borrador'}
									className={'btn-primary'}
									btnStyle="accept"
									disabled={disabledButtons() || isLoading}
									onButtonClick={() => { borradorPDF(); }}
									type="button"
									text="Ver borrador"
								/>
								<CustomButton
									key={`clonarOferta`}
									title={'Clonar'}
									className={'btn-primary'}
									btnStyle="accept"
									disabled={disabledButtons() || isLoading}
									onButtonClick={() => { clonarOfertas(); }}
									type="button"
									text="Clonar"
								/>
								<CustomButton
									key={`clonarEnviasOfertas`}
									title={'Clonar'}
									className={'btn-primary'}
									btnStyle="accept"
									disabled={disabledButtonsEnviar() || isLoading}
									onButtonClick={() => { clonarEnviasOfertas(); }}
									type="button"
									text="Clonar y Enviar Ofertas"
								/>
							</div>
						</div>
					</>
				}
			></ModalPage>
		</div>
	)
}

export default ClonarOfertasModalSection;