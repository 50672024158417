import FormFieldGroup from "../../../objects/FormFieldGroup/FormField.Group"
import "./FormInput.scss";
import Input from "./Input";

interface Props {
	label: string
	value: string
	error?: string
	required?: boolean
	disabled?: boolean,
	placeholder?: string,
	type?:string,
	className?:string,
	idTest?:string,
	onChange: (value: any) => void,
	min?: number,
	maxLength?:number
}

function formatString(texto:string) {
    // Remover espacios al principio y al final
    texto = texto.trim();

    // Eliminar asteriscos
    texto = texto.replace(/\*/g, '');

    // Eliminar acentos
    texto = texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    // Convertir las primeras letras en mayúsculas y juntar las palabras
    texto = texto.split(/\s+/).map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase()).join('');

    return texto;
}

const FormInput: React.FC<Props> = (
	{
		label,
		value,
		error,
		required,
		disabled = false,
		placeholder,
		type,
		className,
		idTest,
		onChange,
		min,
		maxLength
	}
) => {

	return (
			<div className={`input-container ${disabled ? 'input-container--disabled' : ''}`} >
			<FormFieldGroup
				type="input"
				error={error !== undefined}
			>
				<label>
					{label}{required && ' *'}
				</label>
				<Input
					value={value}
					disabled={disabled}
					placeholder={placeholder}
					onChange={onChange}
					type={type}
					min={min}
					maxLength={maxLength}
					className={className}
					data = {idTest}
				/>
			</FormFieldGroup>
			<label className="error">{error && <span>{error}</span>}</label>
			</div>
	)
}

export default FormInput;