
//#region OBJECT UTILITIES

import Constantes from "../assets/constants/Constantes";

/**
 * Devuelve una cadena que representa el nombre del atributo
 * pasado en el argumento key, que se encuentra en el objeto del tipo TObject
 * Si se indica el argumento de tipo lanzará error si el argumento key no es 
 * un atributo válido del tipo indicado en TObject
 * @param obj Una instancia del objeto de tipo TObjecto
 * @param key (Opcional) Clave que identifica un atributo perteneciente al tipo TObject
 */
export function nameof<TObject>(obj: TObject, key: keyof TObject): string;

/**
 * Devuelve una cadena que representa el nombre del atributo
 * pasado en el argumento key, que se encuentra en el objeto del tipo TObject
 * Si se indica el argumento de tipo lanzará error si el argumento key no es 
 * un atributo válido del tipo indicado en TObject
 * @param key Clave que identifica un atributo perteneciente al tipo TObject
 */
export function nameof<TObject>(key: keyof TObject): string;
export function nameof(key1: any, key2?: any): any {
	return key2 ?? key1;
}

/**
 * Helper que devuelve el valor de atributo indicado por key
 * para una instancia de un objeto dado.
 * @param object Una instancia de un objeto de cualquier tipo
 * @param key Atributo del tipo TObject
 * @returns Valor asociado al atributo en la instancia del objeto
 */
export function valueof<TObject>(obj: any, key: keyof TObject): any {

	let indexOfValueAttribute = Object.keys(obj).indexOf(nameof(key));

	return Object.values(obj)[indexOfValueAttribute];
}

/**
 * Devuelve un objeto clave valor que asocia a keyAttribute 
 * el valor, convertido a string, de valueAttribute 
 * para cada elemento del array proporcionado.
 * @param array Array de elementos del tipo TObject
 * @param keyAttribute Atributo de TObject que sirve de clave
 * @param valueAttribute Atributo cuyo valor en TObject se asocia
 * @returns Objeto clave valor mapeado
 */
export function mapEntityArray<TObject extends object>(
	array: Array<TObject>,
	keyAttribute: keyof TObject,
	valueAttribute: keyof TObject
): { [key: string]: string } {
	let temporalKeyValue: { [key: string]: string } = {};

	array.forEach(arrayItem => {
		let key: string = valueof(arrayItem, keyAttribute);
		let value: string = valueof(arrayItem, valueAttribute);

		temporalKeyValue = { ...temporalKeyValue, [key]: value };
	});

	return temporalKeyValue;
}

/**
 * Devuelve un objeto clave valor que asocia a keyAttribute
 * una instancia de TDest, construida a partir de cada elemento
 * del array proporcionado
 * @param array Array de elementos del tipo TObject
 * @param keyAttribute Atributo de TObject que sirve de clave
 * @param attributes Aray de atributos que incluir en la instancia de TDest
 * @returns Objeto clave valor mapeado
 */
export function mapEntityArrayToDest<TObject extends object, TDest extends object>(
	array: Array<TObject>,
	keyAttribute: keyof TObject,
	attributes: Array<keyof TObject>,
): { [key: string]: TDest } {
	let temporalKeyValue: { [key: string]: TDest } = {};

	array.forEach(arrayItem => {
		let key: string = valueof(arrayItem, keyAttribute);

		attributes.forEach(attr => {
			let value = valueof(arrayItem, attr);
			temporalKeyValue = {
				...temporalKeyValue, [key]: {
					...temporalKeyValue[key],
					[attr]: value
				}
			};

		})

	});

	return temporalKeyValue;
}

export const validateEmail = (email: string) => {
	return email?.match(Constantes.EMAIL_FORMAT_REGEX)
};

/** * Agrupa los elementos de un array por una clave generada por la función de callback. 
 * @param {Array} array - Array de elementos a agrupar. 
 * @param {Function} keyGetter - Función que devuelve la clave de agrupamiento para cada elemento. 
 * @returns {Map} - Un objeto Map que agrupa los elementos por claves. 
 * */

export const groupByCustom = (array: any, keyGetter: any) => {
	const map = new Map();
	array.forEach((item: any) => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
}

//#endregion
