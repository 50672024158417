import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PAVASAL_CLIENTEID } from "../../../../../assets/constants/Constantes";
import LabelButton from "../../../../../components/ui/atoms/Buttons/LabelButton";
import Dropdown, { DropdownProps } from "../../../../../components/ui/atoms/Dropdown/Dropdown";
import ModalPage from "../../../../../components/ui/atoms/ModalPage/ModalPage";
import BottomButtons from "../../../../../components/ui/molecules/Bottom-buttons/BottomButtons";
import FormField from "../../../../../components/ui/molecules/Form-field/FormField";
import { useLoading } from "../../../../../context/Loading.Context";
import CustomInput from "../../../../../shared/components/Inputs/CustomInput/CustomInput";
import { formatDate2input, formatDate2String, formatDDMMYYYY, formatYYYYMMDD, quitarFechasVacias } from "../../../../../utils/DateUtil";
import { formatNumber } from "../../../../../utils/NumberUtil";
import ContactosRepository from "../../../../Clientes/domain/ContactosRepository";
import ContactoCliente from "../../../../Clientes/domain/model/ContactosCliente";
import { default as ContactosClientesApiRepository } from "../../../../Clientes/infraestructure/api/ContactosClientes.ApiRepository";
import ContratoRepository from "../../../../Contratos/domain/ContratoRepository";
import TipoFormaPagoContratoRepository from "../../../../Contratos/domain/TipoFormaPagoContratoRepository";
import Contrato from "../../../../Contratos/domain/model/Contrato";
import FormaPagoContrato from "../../../../Contratos/domain/model/FormaPagoContrato";
import TipoFormaPagoContrato from "../../../../Contratos/domain/model/TipoFormaPagoContrato";
import ContratoApiRepository from "../../../../Contratos/infraestructure/api/ContratoApiRepository";
import TipoFormaPagoContratoApiRepository from "../../../../Contratos/infraestructure/api/TipoFormaPagoContratoApiRepository";
import { AuthContext } from "../../../../Login/AuthContextProvider";
import Presupuesto, { NumeroPresupuestoFormat } from "../../../../Presupuestos/domain/model/Presupuesto";
import Ofertas from "../../../domain/model/Ofertas";
import "./EstudiosOfertas.ModalSection.scss";
import FormInput from "../../../../../components/ui/atoms/Input/FormInput/FormInput";

export interface ContratoOfertasModalSectionProps {
	opened?: boolean
	oferta:Ofertas,
	presupuestoContratado:Presupuesto,
	handleCloseModal: () => void
	handleRefresh: () => void
}

const ContratoOfertasModalSection: React.FC<ContratoOfertasModalSectionProps> = (
	{
		opened,
		oferta,
		handleCloseModal,
		handleRefresh,
		presupuestoContratado

	}
) => {

	const { getToken, getDelegacion } = useContext(AuthContext);
	const { setLoading } = useLoading();
	
	const [contratoActual, setContratoActual]=useState<Contrato>(oferta.contrato ? 
		{...oferta.contrato, fechaContrato: formatDate2String(formatDDMMYYYY(oferta.contrato.fechaContrato.split('T')[0])),  fechaEnvio: oferta.contrato.fechaEnvio ? quitarFechasVacias(formatDate2String(formatDDMMYYYY(oferta.contrato.fechaEnvio.split('T')[0]))) : ""} : 
		new Contrato({
			condicionesParticulares:"",
			fechaContrato: formatDate2String(formatDDMMYYYY(new Date().toISOString().split('T')[0])),
			fechaEnvio: formatDate2String(formatDDMMYYYY(new Date().toISOString().split('T')[0])),
			firmantes:[],
			formaPagoContrato: new FormaPagoContrato({diasDuplicadoActa:0,
				etapas:1,
				importeEtapa:1,
				plazoEjecucion:"",
				plazoGarantia:"",
				retencion:0,
				tipoFormaPagoContratoId:1,
				formaPagoExtendida:"",

			}),
			importePresupuesto:presupuestoContratado.totalPresupuesto,
		referenciaPresupuesto:presupuestoContratado.presupuestoVersion.toString(),
		pdfUrl:"",
		ofertaId:oferta.id

	}))


	const [todosFirmantesCliente, setTodosFirmantesCliente]=useState<ContactoCliente[]>([]);
	const [todosFirmantesPavasal, setTodosFirmantesPavasal]=useState<ContactoCliente[]>([]);
	const [tiposFormasPagoContrato, setTiposFormasPagoContrato]=useState<TipoFormaPagoContrato[]>([]);

	const fetchFormasPago=()=>{
		const formasPagoRepo: TipoFormaPagoContratoRepository = new  TipoFormaPagoContratoApiRepository(getToken());
		formasPagoRepo.getAll().then((response)=>{
			setTiposFormasPagoContrato(response)
		});

	}
	const fetchFirmantes=()=>{
		const contactosRepo: ContactosRepository = new ContactosClientesApiRepository(getToken());
		contactosRepo.postClientes(+oferta.clienteId,[],true).then((response)=>{
			setTodosFirmantesCliente(response)
		});

		contactosRepo.postClientes(PAVASAL_CLIENTEID, [oferta.delegacionId,0],true).then((response)=>{
			setTodosFirmantesPavasal(response);
		})
	}

	useEffect(()=>{
		fetchFirmantes();
		fetchFormasPago();
	},
	[oferta])



	//acciones
	const save=()=>{
		const repo: ContratoRepository =new ContratoApiRepository(getToken());
		setLoading(true);
		let firmantesChange:any[]=[];
		contratoActual.firmantes.forEach(element => {
			firmantesChange.push({contactoId:element.contactoId})
		 });
		if(contratoActual.id)
		{
			repo.update({...contratoActual, firmantes:firmantesChange, fechaContrato:formatYYYYMMDD(contratoActual.fechaContrato.split('T')[0]), fechaEnvio:((contratoActual.fechaEnvio && contratoActual.fechaEnvio != "") ? formatYYYYMMDD(contratoActual.fechaEnvio.split('T')[0]) : null)}).then((resp)=>{
				toast.success("Contrato actualizado correctamente");
				setLoading(false);
				handleCloseModal();
			});
		}
		else{
			repo.add({...contratoActual, firmantes:firmantesChange,fechaContrato:formatYYYYMMDD(contratoActual.fechaContrato.split('T')[0]), fechaEnvio:((contratoActual.fechaEnvio && contratoActual.fechaEnvio != "") ? formatYYYYMMDD(contratoActual.fechaEnvio.split('T')[0]) : null)}).then((resp)=>{
				toast.success("Contrato añadido correctamente");
				setLoading(false);
				handleCloseModal();
			});
		}
	}

	const updateRetencion = (retencion: any) => {
			let formaPagoMod={...contratoActual.formaPagoContrato};
			formaPagoMod.retencion=retencion;
			setContratoActual({...contratoActual, formaPagoContrato:formaPagoMod})
	}
	const saveAndSend=()=>{
		
	}

	const disabledSave=()=>{
	return todosFirmantesPavasal &&  todosFirmantesPavasal.length>0 && !todosFirmantesPavasal.some(r=> contratoActual.firmantes.map(p=>{return p.contactoId}).includes(r.id)) || todosFirmantesCliente &&  todosFirmantesCliente.length>0 && !todosFirmantesCliente.some(r=> contratoActual.firmantes.map(p=>{return p.contactoId}).includes(r.id))
		|| (todosFirmantesPavasal && todosFirmantesPavasal.length==0) || (todosFirmantesCliente && todosFirmantesCliente.length==0)
		|| (contratoActual.fechaContrato=='' ||  contratoActual.fechaContrato==undefined)
		|| (contratoActual.formaPagoContrato.formaPagoExtendida == '' || contratoActual.formaPagoContrato.formaPagoExtendida ==undefined)
	 ;
	}

	const calculoTotalPresupuesto = (contrato: Contrato) => {
		const totalVentas = +contrato.importePresupuesto;
		const gastos = totalVentas * (oferta.gastos !== undefined && oferta.gastos > 0 ? oferta.gastos / 100 : 0);
		const beneficio = totalVentas * (oferta.beneficio !== undefined && oferta.beneficio > 0 ? oferta.beneficio / 100 : 0);
		const baseImponible = totalVentas + beneficio + gastos;
		const descuento = baseImponible * (oferta.descuento !== undefined && oferta.descuento > 0 ? oferta.descuento / 100 : 0);
		const baseImpDescuento = baseImponible - descuento;
		const totalVentaIva = baseImpDescuento * (oferta.iva !== undefined && oferta.iva > 0 ? oferta.iva / 100 : 0);

		return (Number((baseImpDescuento) + totalVentaIva).toFixed(2))
    }


	return (
		<div>
			<ModalPage
				handleCloseModal={handleCloseModal}
				opened={opened}
				fields={
					<div>
					<div className="double-col-grid">
						<table className="mini-table-45">
								<thead>
									<tr className='table-header'>
										{todosFirmantesCliente && todosFirmantesCliente.length>0 ? <th>{todosFirmantesCliente[0].clienteNombre}</th>:<th>No existen firmantes para este cliente</th>}
									</tr>
								</thead>
								<tbody>
									{todosFirmantesCliente && todosFirmantesCliente.length>0 && todosFirmantesCliente.map((firmante, index) => (
										<tr key={`firmcli${index}`}>
											<td>
												<input
													type="checkbox"
													checked={contratoActual.firmantes && contratoActual.firmantes.map(p=>{return p.contactoId}).indexOf(firmante.id) > -1 }
													onChange={(e) => {   
														if(contratoActual.firmantes.find(p=>p.contactoId==firmante.id))
															{
																setContratoActual({...contratoActual, firmantes:contratoActual.firmantes.filter(p=>p.contactoId!=firmante.id)})
															}
															else
															{
																let nuevosFirmantesCliente=contratoActual.firmantes;
																nuevosFirmantesCliente.push({contactoId:firmante.id});
																setContratoActual({...contratoActual, firmantes:nuevosFirmantesCliente})
															}
														}
														}
												/><label>{firmante.personaContacto} - {firmante.nif}</label>
											</td>
										</tr>
									))}
								</tbody>
							</table>

							<table className="mini-table-45">
								<thead>
									<tr className='table-header'>
										{todosFirmantesPavasal && todosFirmantesPavasal.length>0 ? <th>{todosFirmantesPavasal[0].clienteNombre}</th>: <th>No existen firmantes para Pavasal</th>}
									</tr>
								</thead>
								<tbody>
									{todosFirmantesPavasal && todosFirmantesPavasal.length>0 && todosFirmantesPavasal.map((firmante, index) => (
										<tr key={`firmpav${index}`}>
											<td>
												<input
													type="checkbox"
													checked={contratoActual.firmantes && contratoActual.firmantes.map(p=>{return p.contactoId}).indexOf(firmante.id) > -1 }
													onChange={(e) => {   
														if(contratoActual.firmantes.find(p=>p.contactoId==firmante.id))
															{
																setContratoActual({...contratoActual, firmantes:contratoActual.firmantes.filter(p=>p.contactoId!=firmante.id)})
															}
															else
															{
																let nuevosfirmantesPavasal=contratoActual.firmantes;
																nuevosfirmantesPavasal.push({contactoId:firmante.id});
																setContratoActual({...contratoActual, firmantes:nuevosfirmantesPavasal})
															}
														}
														}
												/><label>{firmante.personaContacto} - {firmante.nif}</label>
											</td>
										</tr>
									))}
								</tbody>
							</table>
					</div>
						<div className="padding-bg">
								
							{
							//condiciones
							}
							<div className="double-col-grid">
							<CustomInput
								label="Numero presupuesto"
                                    key={`numeropresupuesto`}
                                    value={ NumeroPresupuestoFormat(oferta,  presupuestoContratado)}
                                    className={'platform-input secondary-input'}
                                    onChange={(e) => { setContratoActual({...contratoActual, referenciaPresupuesto:e})}}
                                    disabled={true}
                                    formClassName={"secondary-input"}
                                    type="text"
                                />
								<CustomInput
								label="Importe presupuesto"
                                    key={`importepresupuesto`}
									value={ formatNumber(contratoActual.importePresupuesto ? +calculoTotalPresupuesto(contratoActual) : 0)}
                                    className={'platform-input secondary-input'}
                                    onChange={(e) => { setContratoActual({...contratoActual, importePresupuesto:e})}}
                                    disabled={true}
                                    formClassName={"secondary-input"}
                                    type="text"
                                />
								</div>
							<div className="double-col-grid">
								<CustomInput
								label="Fecha Contrato"
										key={`$fechaContrato`}
										value={ contratoActual.fechaContrato}
										className={'platform-input secondary-input'}
										onChange={(e) => { setContratoActual({...contratoActual, fechaContrato:e})}}
										disabled={false}
										formClassName={"secondary-input"}
										type="date"
								/>
								<CustomInput
								label="Fecha Envío"
										key={`$fechaEvio`}
										value={ contratoActual.fechaEnvio ? contratoActual.fechaEnvio : ""}
										className={'platform-input secondary-input'}
										onChange={(e) => { setContratoActual({...contratoActual, fechaEnvio:e})}}
										disabled={false}
										formClassName={"secondary-input"}
										type="date"
								/>
							
								
							</div>
							<CustomInput
								  type='text'
								  resizable={true}
								  label="Condiciones particulares" 
								  key="condiciones"
								  value={contratoActual.condicionesParticulares ?? ""}
								  onChange={(e) => {
									
									setContratoActual({...contratoActual, condicionesParticulares: e})}}
								  required={true}
							  	/>
							{
							//formas de pago
							
							}
							<div className=" padding-bg accent-bg">
							<h3>Forma de pago</h3>
							<div className="double-col-grid">
							 <CustomInput
								  type='text'
								  label="Forma Pago extendido *" 
								  key="formapagoext"
								  value={contratoActual.formaPagoContrato.formaPagoExtendida ?? ""}
								  onChange={(e) =>{
									let formaPagoMod={...contratoActual.formaPagoContrato};
									formaPagoMod.formaPagoExtendida=e;
									setContratoActual({...contratoActual, formaPagoContrato:formaPagoMod})}}
								  required={true}
							  />
						
								<FormField<DropdownProps>
								label="Tipo Pago"
								options={tiposFormasPagoContrato.map(
									(element) => {
									return {
										text: element.descripcion ,
										id: element.id,
										selected: element.id && contratoActual.formaPagoContrato.tipoFormaPagoContratoId == +element.id ? true : false,
										onClick: () => {
											let formaPagoMod={...contratoActual.formaPagoContrato};
											formaPagoMod.tipoFormaPagoContratoId=+element.id!;

											setContratoActual({...contratoActual, formaPagoContrato:formaPagoMod})
										}
									}
								})}
								required={true}
								disabled={false}
								singleSelection={true}
								component={Dropdown}/>
								</div>

		
							<div className="double-col-grid">
							<CustomInput
								  type='text'
								  label="Plazo Ejecución" 
								  key="plazoeje"
								  value={contratoActual.formaPagoContrato.plazoEjecucion.toString()}
								  onChange={(e) =>{
									let formaPagoMod={...contratoActual.formaPagoContrato};
									formaPagoMod.plazoEjecucion=e;
									setContratoActual({...contratoActual, formaPagoContrato:formaPagoMod})}}
								  required={true}
							  />
							  
							<CustomInput
								  type='text'
								  label="Plazo de garantía" 
								  key="plazogaran"
								  value={contratoActual.formaPagoContrato.plazoGarantia}
								  onChange={(e) =>{
									let formaPagoMod={...contratoActual.formaPagoContrato};
									formaPagoMod.plazoGarantia=e;
									setContratoActual({...contratoActual, formaPagoContrato:formaPagoMod})}}
								  required={true}
							  />
							  </div>
							<div className="double-col-grid">
							<CustomInput
								  type='number'
								  label="Etapas" 
								  key="etapas"
								  value={contratoActual.formaPagoContrato.etapas.toString()}
								  onChange={(e) =>{
									let formaPagoMod={...contratoActual.formaPagoContrato};
									formaPagoMod.etapas=+e;
									setContratoActual({...contratoActual, formaPagoContrato:formaPagoMod})}}
								  required={true}
							  />
							<CustomInput
								  type='number'
								  label="Importe Etapa" 
								  key="etapasimp"
								  value={contratoActual.formaPagoContrato.importeEtapa.toString()}
								  onChange={(e) =>{
									let formaPagoMod={...contratoActual.formaPagoContrato};
									formaPagoMod.importeEtapa=+e;
									setContratoActual({...contratoActual, formaPagoContrato:formaPagoMod})}}
								  required={true}
							  />
							  </div>
							  <div className="double-col-grid">
							 <FormInput
								  type='number'
								  label="Retención" 
								  key="reten"
								  value={contratoActual.formaPagoContrato.retencion.toString()}
								  onChange={(e) =>{
									updateRetencion(e.target.value)
									}
								  }
								  required={true}
							  />
							<CustomInput
								  type='number'
								  label="Días duplicado Acta/Doc. Inicio" 
								  key="duplicadoactdoc"
								  value={contratoActual.formaPagoContrato.diasDuplicadoActa.toString()}
								  onChange={(e) =>{
									let formaPagoMod={...contratoActual.formaPagoContrato};
									formaPagoMod.diasDuplicadoActa=+e;
									setContratoActual({...contratoActual, formaPagoContrato:formaPagoMod})}}
								  required={true}
							  />
							  </div>
						</div>
						
						<div className="buttons-right">
							<BottomButtons>
								<LabelButton
									disabled={ disabledSave()}
									label="Guardar"
									type="button"
									onClick={save}
								/>
                        	</BottomButtons>
						</div>
						{/*
							contratoActual.pdfUrl &&
							<embed width={"100%"} height={"400px"} src={contratoActual.pdfUrl} ></embed>
							*/
						}
					</div>
					</div>

				}

				modalTitle="Contrato"
				bodyClassName='--large'
			>

			</ModalPage>


		</div>
	)
}

export default ContratoOfertasModalSection;