import { useContext } from "react";
import OportunidadesSuggestionInput from "../../../../Oportunidades/views/atoms/SuggestionInputs/Oportunidades.SuggestionInput";
import { CodigoPostalFieldContext } from "../../../context/ModalFields.Context";


const CodigoPostalSuggestionInput: React.FC<{ idTest?:string }> = ({idTest}) => {

	const field = useContext(CodigoPostalFieldContext);

	return (
		<OportunidadesSuggestionInput
			label="Municipio"
			input={field.input}
			suggestions={field.suggestions}
			onInputChange={field.onInputChange}
			idTest={idTest}
		/>
	)
}

export default CodigoPostalSuggestionInput;