import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Dropdown, { DropdownProps } from "../../../../components/ui/atoms/Dropdown/Dropdown";
import SelectableItem from "../../../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import FormField from "../../../../components/ui/molecules/Form-field/FormField";
import { HistoryHandler } from "../../../../context/History.Context";
import { useLoading } from "../../../../context/Loading.Context";
import { Routes } from "../../../../router/routes/Routes";
import { AuthContext } from "../../../Login/AuthContextProvider";
import Licitacion from "../../../Oportunidades/domain/model/Licitacion";
import OfertasRepository from "../../domain/OfertasRepository";
import { OfertasCreationDto, TipoOfertaEnum } from "../../domain/model/Ofertas";
import OfertasApiRepository from "../../infraestructure/api/Ofertas.ApiRepository";
import CodigoLicitacionSearchField from "../atoms/CodigoLicitacion.SearchField";
import OfertasFormTemplate from "../common/Ofertas.FormTemplate";
import ClienteRepository from "../../../Clientes/domain/ClienteRepository";
import ClienteApiRepository from "../../../Clientes/infraestructure/api/Cliente.ApiRepository";
import AdjudicacionRepository from "../../../../domain/AdjudicacionRepository";
import AdjudicacionApiRepository from "../../../../infraestructure/api/Adjudicacion.ApiRepository";
import Cliente from "../../../Clientes/domain/model/Cliente";
import TipoOferta from "../../domain/model/TipoOferta";
import TipoOfertaRepository from "../../domain/TipoOfertaRepository";
import TipoOfertaApiRepository from "../../infraestructure/api/TipoOferta.ApiRepository";

const OfertasGrabacion: React.FC<{}> = () => {

	const [licitacion, setLicitacion] = useState<Licitacion>();

	const { goToRoute } = useContext(HistoryHandler);
	const { getToken, getDelegacion } = useContext(AuthContext);
	const { setLoading } = useLoading();

	const [sino, setSino] = useState<SelectableItem[]>([{ id: '1', descripcion: "SÍ" } as SelectableItem, { id: '2', descripcion: "NO" } as SelectableItem]);
	const [selected, setSelected] = useState<SelectableItem>({ id: '2', descripcion: "NO" } as SelectableItem);
	const [optionsTipoOferta, setOptionsTipoOferta] = useState<TipoOferta[]>([]);
	const [selectedTipoOferta, setSelectedTipoOferta] = useState<TipoOferta>();
	
	const PAVASAL_CONTRATISTAID=1;

	const handleClickSiNo = (sino: SelectableItem) => {
		setSelected(sino);

		if (sino?.id == "2") {
			setLicitacion(undefined);
		}
	}

	const handleClickTipoOferta = ( e: TipoOferta) => {
		setSelectedTipoOferta(e)
	}

	const [clienteLicitacion, setClienteLicitacion] = useState<Cliente>();

	const fetchAdjudicacion = useCallback((codigo: number) => {
		const repoAdjudicaciones: AdjudicacionRepository = new AdjudicacionApiRepository(getToken())
		const repoCliente: ClienteRepository = new ClienteApiRepository(getToken());

		repoAdjudicaciones.fetchAdjudicacion(codigo).then(respAdjudicacion => {
			if (respAdjudicacion && respAdjudicacion.length > 0) {
				let asfaltero = respAdjudicacion;

				if (respAdjudicacion.length > 1) {
					asfaltero = respAdjudicacion.filter(p => p.asfaltero == "0");
					if (asfaltero.length === 0) asfaltero = respAdjudicacion;
				}

				if (asfaltero[0].cif.trim() != "") {
					repoCliente.fetchClienteByCif(asfaltero[0].cif.trim()).then(respCliente => {
						setClienteLicitacion(respCliente);

						if (!respCliente) {
							toast.info("El cliente de la licitación no se ha podido asociar automáticamente con el cliente de la oferta.");
						}
					});
				} else {
					toast.info("El cliente de la licitación no se ha podido asociar automáticamente con el cliente de la oferta.");
				}
			}
		});
	}, [clienteLicitacion]);

	const fetchLicitacion = useCallback((codigo: number) => {
		setLoading(true);
		const repo: OfertasRepository = new OfertasApiRepository(getToken());
		setLicitacion(undefined);
		setClienteLicitacion(undefined);

		repo.getLicitacionByCodigo(codigo).then(resp => {
			if (resp.estado === "ANULADA") {
				toast.error("La licitación no puede estar anulada");
			} else {
				setLicitacion(resp);
				fetchAdjudicacion(resp.codigo);
				if (resp.estadoId == 3)
				{
					toast.info("El tipo de la Oferta actual se cambiará a Interna automáticamente al ser el cliente PAVASAL.");
				}
			}
		})
			.catch((error) => {
				toast.error("No existe licitación asociada al código introducido");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [licitacion]);

	const fetchCreation = useCallback((data: OfertasCreationDto) => {
		setLoading(true);
		const repo: OfertasRepository = new OfertasApiRepository(getToken());
		repo.addOfertas(data)
			.then((res) => {
				toast.success("Oferta creada con éxito");
				goToRoute(Routes.OFERTAS_EDICION, { id: res?.id });
			})
			.catch(error => {
				toast.error(error);
			})
			.finally(() => setLoading(false));
	}, [goToRoute]);

	const fetchTipoOferta = useCallback(() => {
		const repo: TipoOfertaRepository = new TipoOfertaApiRepository(getToken());
		repo.getAll().then((resp) => {
			setOptionsTipoOferta(resp);
		});
	}, [getToken]);

	useEffect(() => {
		fetchTipoOferta()


	}, [fetchTipoOferta])

	

	return (
		<div className='add-edit-view-container'>
			<div className='add-edit-header'>
				<h4>Nueva Oferta</h4>
			</div>

			<div className="add-edit-body">
				<div className="row-of-three">
					{/* <div className="row-of-two" style={{ marginBottom: "0" }}> */}
						<FormField<DropdownProps>
							label="Licitación Asociada"
							options={sino.map(
								(element) => {
									return {
										text: element.descripcion,
										id: element.id,
										selected: selected?.id == element.id ? true : false,
										onClick: () => { handleClickSiNo(element); }
									}
								})}
							required={true}
							disabled={false}
							singleSelection={true}
							component={Dropdown}
							size={"litle"}
						/>

						{optionsTipoOferta && selected.id == "1" &&
							<FormField<DropdownProps>
								label="Tipo Oferta"
								options={optionsTipoOferta
									.filter(p => (p.id != TipoOfertaEnum.Ejecucion.toString() && p.id != TipoOfertaEnum.Interna.toString() && p.id != TipoOfertaEnum.Previa.toString()))
									.map(
										(element) => {
											return {
												text: element.descripcion,
												id: element.id,
												selected: selectedTipoOferta?.id == element.id ? true : false,
												onClick: () => { handleClickTipoOferta(element); }
											}
										})}
								required={false}
								disabled={licitacion != null}
								singleSelection={true}
								component={Dropdown}
								idTest="TipoOferta"
							/>

						}
					{/* </div> */}
					{
						selected && selected.id == "1" &&
						<CodigoLicitacionSearchField
							fetchLicitacion={(e) => {
								fetchLicitacion(e)
							}}
						/>
					}
				</div>
				<div>
					{
						((selected && selected.id == "2")) &&
						<OfertasFormTemplate
							licitacion={{ ...licitacion, promotorId: licitacion?.promotorId.toString() }}
							onSubmit={fetchCreation}
						/>
					}
					{
						licitacion && licitacion?.codigo && clienteLicitacion &&
						<OfertasFormTemplate
							licitacion={{ ...licitacion, promotorId: licitacion?.promotorId.toString() }}
							clienteLicitacion={clienteLicitacion}
							onSubmit={fetchCreation}
							tipoOferta={selectedTipoOferta}
						/>
					}
					{
						licitacion && licitacion?.codigo && !clienteLicitacion &&
						<OfertasFormTemplate
							licitacion={{ ...licitacion, promotorId: licitacion?.promotorId.toString() }}
							onSubmit={fetchCreation}
							tipoOferta={selectedTipoOferta}
						/>
					}
				</div>
			</div>
		</div>
	)
}

export default OfertasGrabacion;