import { useContext } from "react"
import DateSelector from "../../../../components/ui/molecules/Date-selector/DateSelector"
import FormFieldGroup from "../../../../components/ui/objects/FormFieldGroup/FormField.Group"
import { FechaAdjudicacionContext } from "../../context/FilterFields.Context"

const FechaAdjudicacionDateSelector: React.FC<{ idTest?:string }> = ( {idTest} ) => {

	const { onDateSelect } = useContext(FechaAdjudicacionContext);

	return (
		<div className="input-container">
		<FormFieldGroup>
			<label>Fecha Adjudicación / Modificación</label>			
			<DateSelector
			idTest={idTest}
				onDateSelect={onDateSelect}
			/>
			
		</FormFieldGroup>
		</div>
	)
}

export default FechaAdjudicacionDateSelector;