import TablePrintableObject from "../../../../components/Table/types/TablePrintableObject";
import { formatDate2String } from "../../../../utils/DateUtil";
import { formatNumber } from "../../../../utils/NumberUtil";
import Contrato from "../../../Contratos/domain/model/Contrato";
import TipoOferta from "./TipoOferta";

export type OfertasFilter = {
	obraDescripcion: string,
	codigo: string,
	clienteDescripcion: string,
	toneladas: number,
	provinciaIds: number[],
	fechaOfertaDesde: Date,
	fechaOfertaHasta: Date,
	ultimaModificacionDesde: Date,
	ultimaModificacionHasta: Date,
	promotorIds: number[],
	codigoPostalIds: number[],
	delegacionIds: number[],
	tipoOfertaIds: number[],
	minToneladas: number,
	maxToneladas: number
	tipoOferta?: string,
	estadoOfertaIds?: number[],
	clienteIds?: number[],
	licitacionCodigo?: string,
	oportunidadCodigo?: string,
	comercialIds: number[]
};

export type OfertasParams = { [id: string]: any };

export enum TipoOfertaEnum {
	Previa = 1,
	Licitacion = 2,
	Ejecucion = 3,
	Interna = 4,
	Estudio = 5
}

export enum EstadoOfertaEnum {
	EnSeguimiento = 1,
	EnEstudio = 2,
	Ofertada = 3,
	Negociación = 4,
	Contratada = 5,
	Perdida = 6,
	Anulada = 7,
	NoEjecutada = 8,
	NoPresentada = 9,
	Interna = 10
}

interface OfertasEntity {
	id: string
	codigo: string
	obraDescripcion: string
	fechaOferta: Date
	ultimaModificacion: Date
	promotorId: string
	delegacionId: number;
	delegacionDescripcion: string;
	clienteId: string
	clienteDescripcion: string
	codigoPostalId: string;
	codigoPostalDescripcion: string;
	estadoOfertaId: number;
	estadoOfertaDescripcion: string
	motivoId?: string;
	motivoDescripcion?: string;
	clientePerdidaId?: string;
	clientePerdidaDescripcion?: string;
	toneladas: number
	lastModificationTime: string
	lastModificationUsername: string
	tipoOferta: TipoOferta
	tipoOfertaId: number
	licitacionCodigo?: number;
	licitacionEstado?: number;
	tieneAviso: boolean;
	oportunidadCodigo: number;
	provinciaId?: string;
	provinciaDescripcion?: string;
	tipoOfertaDescripcion?: string;
	iva?: number;
	beneficio?: number;
	gastos?: number;
	descuento?: number;
	oportunidadToneladas: number;
	contrato?: Contrato;
	driveUrl: string;
	driveUrlDescripcion: string;
	importePresupuesto: number;
	comercialId: number;
	comercialDescripcion?: string;
	codigoObra?: string;
	
}

export default class Ofertas extends TablePrintableObject {
	id: string;
	codigo: string;
	obraDescripcion: string;
	fechaOferta: Date;
	ultimaModificacion: Date;
	promotorId: string
	delegacionId: number;
	delegacionDescripcion: string;
	clienteId: string;
	clienteDescripcion: string;
	codigoPostalId: string;
	codigoPostalDescripcion: string;
	estadoOfertaId: number;
	estadoOfertaDescripcion: string;
	motivoId?: string;
	motivoDescripcion?: string;
	clientePerdidaId?: string;
	clientePerdidaDescripcion?: string;
	toneladas: number;
	lastModificationTime: string;
	lastModificationUsername: string;
	tipoOferta: TipoOferta;
	tipoOfertaId: number;
	licitacionCodigo?: number;
	licitacionEstado?: number;
	tieneAviso: boolean;
	oportunidadCodigo: number;
	provinciaId?: string;
	provinciaDescripcion?: string;
	tipoOfertaDescripcion?: string;
	iva?: number;
	beneficio?: number;
	gastos?: number;
	descuento?: number;
	oportunidadToneladas: number;
	contrato?: Contrato;
	driveUrl: string;
	driveUrlDescripcion: string;
	importePresupuesto: number;
	comercialId: number;
	comercialDescripcion?: string;
	controllerName="/Ofertas/edicion/";

	codigoObra?: string;

	constructor(entity: OfertasEntity) {
		super(entity.id, entity.obraDescripcion);
		this.id = entity.id;
		this.codigo = entity.codigo;
		this.promotorId = entity.promotorId;
		this.delegacionId = entity.delegacionId;
		this.delegacionDescripcion = entity.delegacionDescripcion;
		this.clienteId = entity.clienteId;
		this.obraDescripcion = entity.obraDescripcion;
		this.fechaOferta = entity.fechaOferta;
		this.ultimaModificacion = entity.ultimaModificacion;
		this.clienteDescripcion = entity.clienteDescripcion;
		this.codigoPostalId = entity.codigoPostalId;
		this.codigoPostalDescripcion = entity.codigoPostalDescripcion;
		this.toneladas = entity.toneladas;
		this.estadoOfertaDescripcion = entity.estadoOfertaDescripcion;
		this.estadoOfertaId = entity.estadoOfertaId;
		this.motivoId = entity.motivoId;
		this.motivoDescripcion = entity.motivoDescripcion;
		this.clientePerdidaId = entity.clientePerdidaId;
		this.clientePerdidaDescripcion = entity.clientePerdidaDescripcion;
		this.lastModificationTime = entity.lastModificationTime;
		this.lastModificationUsername = entity.lastModificationUsername;
		this.tipoOferta = entity.tipoOferta;
		this.tipoOfertaId = entity.tipoOfertaId;
		this.licitacionCodigo = entity.licitacionCodigo;
		this.licitacionEstado = entity.licitacionEstado;
		this.tieneAviso = entity.tieneAviso;
		this.oportunidadCodigo = entity.oportunidadCodigo;
		this.provinciaId = entity.provinciaId;
		this.tipoOfertaDescripcion = entity.tipoOfertaDescripcion;
		this.provinciaDescripcion = entity.provinciaDescripcion;
		this.iva = entity.iva;
		this.beneficio = entity.beneficio;
		this.gastos = entity.gastos;
		this.descuento = entity.descuento;
		this.oportunidadToneladas = entity.oportunidadToneladas;
		this.contrato = entity.contrato;
		this.driveUrl = entity.driveUrl;
		this.driveUrlDescripcion = entity.driveUrlDescripcion;
		this.importePresupuesto = entity.importePresupuesto;
		this.comercialId = entity.comercialId;
		this.comercialDescripcion = entity.comercialDescripcion;
		this.codigoObra = entity.codigoObra;
	}

	override toMap = () => {
		return new Map<keyof Ofertas, string>(
			[
				["codigo", this.codigo],
				["oportunidadCodigo", this.oportunidadCodigo.toString()],
				["licitacionCodigo", this.licitacionCodigo ? this.licitacionCodigo.toString() : ""],
				["fechaOferta", this.fechaOferta !== undefined ? formatDate2String(this.fechaOferta) : ""],
				["ultimaModificacion", this.ultimaModificacion !== undefined ? formatDate2String(this.ultimaModificacion) : ""],
				["obraDescripcion", this.obraDescripcion],
				["clienteDescripcion", this.clienteDescripcion],
				["toneladas", formatNumber(this.toneladas ?? 0)],
				["importePresupuesto", formatNumber(this.importePresupuesto ?? 0)],
				["comercialDescripcion", this.comercialDescripcion !== undefined ? this.comercialDescripcion : ""],
				["tieneAviso", this.tieneAviso ? "true" : "false"],
				["estadoOfertaDescripcion", this.estadoOfertaDescripcion],
				["tipoOfertaDescripcion", this.tipoOfertaDescripcion ?? ""],
				["provinciaDescripcion", this.provinciaDescripcion ?? ""]

			]
		);
	};

	public static toArray(entities: OfertasEntity[]): Ofertas[] {
		return entities?.map(entity => { return new Ofertas(entity) });
	}
}

export interface OfertasDto extends Ofertas { };

export interface OfertasCreationDto {
	codigo?: string;
	delegacionId: number
	clienteId: string,
	clienteDescripcion: string,
	promotorId: string
	promotorDescripcion?: string
	provinciaId?: string
	provinciaDescripcion?: string
	obraDescripcion: string
	fechaOferta: string | Date
	ultimaModificacion: string | Date
	toneladas: number
	estadoOfertaId: number
	estadoOfertaDescripcion: string
	motivoId?: string;
	motivoDescripcion?: string;
	clientePerdidaId?: string;
	clientePerdidaDescripcion?: string;
	codigoPostalId?: string
	codigoPostalDescripcion?: string
	lastModificationTime: string
	lastModificationUsername: string
	tipoOfertaId: number
	licitacionCodigo?: number;
	licitacionEstado?: number;
	tieneAviso?: boolean;
	numeroPresupuesto?: string;
	conctactoId?: string;
	iva?: number;
	beneficio?: number;
	gastos?: number;
	descuento?: number;
	oportunidadToneladas?: number;
	presupuestoContratadoId?: string;
	observaciones?: string;
	comercialId: number;
	codigoObra?: string;
}

export interface OfertasUpdateDto {
	id: string,
	codigo?: string;
	delegacionId: number
	clienteId: string,
	clienteDescripcion: string,
	promotorId: string
	promotorDescripcion?: string
	provinciaId?: string
	provinciaDescripcion?: string
	obraDescripcion: string
	fechaOferta: string | Date
	ultimaModificacion: string | Date
	toneladas: number
	estadoOfertaId: number
	estadoOfertaDescripcion: string
	motivoId?: string;
	motivoDescripcion?: string;
	clientePerdidaId?: string;
	clientePerdidaDescripcion?: string;
	codigoPostalId?: string
	codigoPostalDescripcion?: string
	lastModificationTime: string
	lastModificationUsername: string
	tipoOfertaId: number
	licitacionCodigo?: number;
	tieneAviso?: boolean;
	numeroPresupuesto?: string;
	conctactoId?: string;
	iva?: number;
	beneficio?: number;
	gastos?: number;
	descuento?: number;
	oportunidadToneladas?: number;
	presupuestoContratadoId?: string;
	comercialId: number;
	errors: string[];
}

export interface OfertasCloneDto {
	ofertaId: string
	clienteIds: string[]
	clonarEstudios: boolean
}


export interface CopiarOfertasResponse {
	ofertaId: string,
	presupuestoId: string,
	contactoStr?: string,
	clienteStr: string
}