import TablePrintableObject from "../../../../components/Table/types/TablePrintableObject";
import { SelectableItemDto } from "../../../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import EstudioDetalleAvanzadoElemento from "../../../Estudios/domain/model/EstudioDetalleAvanzadoElemento";
import { UnidadObraElementoTipo } from "./ElementoEquipo";

export type EquiposFilter = {
	filter: {
		nombre?: string,
		descripcion?: string,
		delegacionIds?: number[],
		tipoElementoIds?: number[],
		userDelegacion?: any
	}

};

export type ElementosDtoFilter = {
    filter: {
        descripcion?: string;
		nombre?: string;
		delegacionIds: number[];
    }
};

export interface EquipoDetalle {
	unidadObraElementoId: string;
	unidadTipoDescripcion?: string;
	unidadMedidaDescripcion?: string;
	equipoId?: string;
    rendimiento: string;
	unidadObraElementoDescripcion?: string;
	tarifa: number
	id?: string;
}


interface EquipoEntity {
	id: string,
	delegacionId: number,
	unidadObraElementoTipoId: number, 
	nombre: string,
	descripcion: string;
	equipoDetalles?: EquipoDetalle[];
	// delegacion: Delegacion | null;
	unidadObraElementoTipo: UnidadObraElementoTipo | null,
	delegacionNombre?: string | null | undefined;
	tipoDescripcion?: string | undefined;
	tarifa?: string;
}

export interface TipoUnidadObra {
	descripicion: string
	id: number
}

export default class Equipo extends TablePrintableObject {

	id: string;
	delegacionId: number;
	unidadObraElementoTipoId: number;
	nombre: string;
	descripcion: string;
	equipoDetalles?: EquipoDetalle[];
	// delegacion: Delegacion | null;
	delegacionNombre?: string | null | undefined;
	unidadObraElementoTipo: UnidadObraElementoTipo  | null;
	tipoDescripcion?: string | undefined;
	tarifa?: string;
	controllerName: string;

	constructor(entity: EquipoEntity) {
		super(entity.id, entity.descripcion);
		this.id = entity.id;
		this.delegacionId = entity.delegacionId;
		this.unidadObraElementoTipoId=entity.unidadObraElementoTipoId;
		this.nombre = entity.nombre;
		this.descripcion = entity.descripcion;
		this.equipoDetalles = entity.equipoDetalles;
		// this.delegacion = entity.delegacion;
		this.delegacionNombre = entity.delegacionNombre;
		this.unidadObraElementoTipo = entity.unidadObraElementoTipo ?? null;
		this.tipoDescripcion = entity.unidadObraElementoTipo?.descripcion;
		this.tarifa = entity.tarifa;
		this.controllerName="/mantenimiento/equipos/edicion/";
	}

	public static toArray(entities: EquipoEntity[]): Equipo[] {
		return entities ? entities.map(entity => { return new Equipo(entity) }) : [];
	}

	override toMap = () => {
		return new Map<keyof Equipo, string>(
			[

			]
		);
	};
}


export interface EquipoDto extends Equipo {
	errors?: string[]
};


export interface EquipoCreateDto {
	delegacionId: number,
	unidadObraElementoTipoId: number, 
	nombre: string,
	descripcion: string,
	equipoDetalles?: EquipoDetalle[];
	errors?: string[]
};

export interface EquiposConTarifaDto {
	id: string,
	nombre: string,
	descripcion: string,
	tarifa: number
}

export interface EquiposSelectable extends SelectableItemDto {
	id: string,
	text: string
	nombre: string,
	descripcion: string,
	tarifa: string
}

export interface EquipoReadDto extends Equipo {
    errors?: string[]
}
// export interface EquipoUpdateDto extends Equipo {
//     errors?: string[]
// }

export interface EquipoUpdateDto {
	id: string
	delegacionId: number
    unidadObraElementoTipoId: number
    nombre: string
	elemento?: EstudioDetalleAvanzadoElemento[]
    descripcion: string
	equipoDetalles: EquipoDetalle[]
    errors?: string[]
}

export interface EquipoCalculo {
	id?: string;
	equipoId:string,
	descripcion: string,
	unidades:string
	elementos: EstudioDetalleAvanzadoElemento[],
	tarifa: string,
	costeEquipo: number,
	dias: string,
	orden:number
}



