import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import IconButton from "../../../../../../components/ui/atoms/Buttons/IconButton";
import Dropdown, { DropdownProps } from "../../../../../../components/ui/atoms/Dropdown/Dropdown";
import FormInput from "../../../../../../components/ui/atoms/Input/FormInput/FormInput";
import Input from "../../../../../../components/ui/atoms/Input/FormInput/Input";
import ModalPage from "../../../../../../components/ui/atoms/ModalPage/ModalPage";
import FormField from "../../../../../../components/ui/molecules/Form-field/FormField";
import TooltipComponent from "../../../../../../components/ui/molecules/Tooltip/TooltipComponent";
import { MaterialCalculo } from "../../../../../../domain/model/Material";
import UnidadMedida from "../../../../../../domain/model/UnidadMedida";
import { FormErrors } from "../../../../../../hooks/use-form";
import { CustomButton } from "../../../../../../shared/components/Buttons/CustomButton";
import { formatNumber } from "../../../../../../utils/NumberUtil";
import EquiposRepository from "../../../../../Equipos/domain/EquiposRepository";
import { EquipoCalculo } from "../../../../../Equipos/domain/model/Equipo";
import EquipoApiRepository from "../../../../../Equipos/infrastructure/api/Equipo.ApiRepository";
import EstudioDetalleApiRepository from "../../../../../Estudios/Infraestructure/api/EstudioDetalle.ApiRepository";
import EstudioDetalleRepository from "../../../../../Estudios/domain/EstudioDetalleRepository";
import EstudioDetalle from "../../../../../Estudios/domain/model/EstudioDetalle";
import EstudioDetalleAvanzado from "../../../../../Estudios/domain/model/EstudioDetalleAvanzado";
import EstudioDetalleAvanzadoElemento from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoElemento";
import EstudioDetalleAvanzadoEquipo from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoEquipo";
import EstudioDetalleAvanzadoMaterial from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoMaterial";
import EstudioDetalleAvanzadoTramo, { EstudioDetalleAvanzadoTramoDto } from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoTramo";
import EstudioDetalleAvanzadoTraslado from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoTraslado";
import { AuthContext } from "../../../../../Login/AuthContextProvider";
import UnidadObraElementoRepository from "../../../../../Unidades/domain/UnidadObraElementoRepository";
import UnidadesRepository from "../../../../../Unidades/domain/UnidadesRepository";
import { UnidadEquipoReadDto } from "../../../../../Unidades/domain/model/UnidadEquipo";
import { UnidadMaterialReadDto } from "../../../../../Unidades/domain/model/UnidadMaterial";
import { UnidadObraElementoReadDto } from "../../../../../Unidades/domain/model/UnidadObraElemento";
import UnidadObraElementoApiRepository from "../../../../../Unidades/infraestructure/api/UnidadObraElementoApiRepository";
import UnidadesApiRepository from "../../../../../Unidades/infraestructure/api/UnidadesApiRepository";
import EstudioDetalleAvanzadoEquipoRepository from "../../../../domain/EstudioDetalleAvanzadoEquipoRepository";
import EstudioDetalleAvanzadoTrasladoRepository from "../../../../domain/EstudioDetalleAvanzadoTrasladoRepository";
import EstudioDetalleAvanzadoEquipoApiRepository from "../../../../infraestructure/api/EstudioDetalleAvanzadoEquipo.ApiRepository";
import EstudioDetalleAvanzadoTrasladoApiRepository from "../../../../infraestructure/api/EstudioDetalleAvanzadoTraslado.ApiRepository";
import CalculoAvanzadoElementosEquipo from "./CalculoAvanzado.ElementosEquipo";
import "./CalculoAvanzado.scss";
import { updateDiasRendimiento, updateDiasTraslado, updateEquipos, updateMateriales, updateTotales, updateTransporte } from "./Calculos";
import { UnidadReadDto } from "../../../../../Unidades/domain/model/Unidad";
import { useLoading } from "../../../../../../context/Loading.Context";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import CustomInput from "../../../../../../shared/components/Inputs/CustomInput/CustomInput";

interface Props {
	openModal: boolean
	handleCloseModal: (estudioDetalleArray: EstudioDetalle[]) => void
	handleCloseCancelModal: () => void
	unidadObraId?: string
	unidadObraDescripcion?: string
	estudioDetalleDescripcion: string[]
	unidadMedida: UnidadMedida
	estudioDetalleAvanzado?: EstudioDetalleAvanzado,
	estudioDetalleId?: string,
	estudioDetalleAvanzadoId?: string,
	coeficienteEstudio: number,
	delegacionId: string,
	readOnly?: boolean,
	estudiosDetalleLength: number
}



const CalculoAvanzadoZahorra: React.FC<Props> = ({ openModal,
	handleCloseModal,
	handleCloseCancelModal,
	unidadObraDescripcion,
	estudioDetalleDescripcion,
	unidadMedida,
	estudioDetalleAvanzado,
	estudioDetalleAvanzadoId,
	unidadObraId,
	coeficienteEstudio,
	delegacionId,
	readOnly,
	estudiosDetalleLength }) => {

	const ZAHORRA_STR: string = estudioDetalleDescripcion[0] ?? "ZAHORRA";
	const ZAHORRA_CALCULOAVANZADOID: number = 3;

	const UNIDAD_OBRA_ELEMENTO_TIPO_TRASLADO = 5;
	const CALCULO_AVANZADO_ID_ZAHORRA = 3;

	const { getToken, getDelegacion } = useContext(AuthContext);
	const { setLoading } = useLoading();
	const [estudioDetalles, setEstudioDetalles] = useState<EstudioDetalle[]>();
	const [formErrors, setFormErrors] = useState<FormErrors<EstudioDetalleAvanzado>>({});
	const [materialesUnidad, setMaterialesUnidad] = useState<UnidadMaterialReadDto[]>([]);
	const [materialesCalculos, setMaterialesCalculos] = useState<MaterialCalculo[]>(
		estudioDetalleAvanzado?.material != undefined ?
			estudioDetalleAvanzado?.material?.map((entity, index) => {
				return new MaterialCalculo({
					id: entity.id,
					codigoArticulo: entity.codigoArticulo,
					materialId: entity.materialId,
					densidad: entity.densidad,
					espesor: entity.espesor,
					superficie: entity.superficie,
					tmNecesarias: entity.toneladas,
					costeMezcla: 0,
					mezcla: entity.materialDescripcion!,
					precio: entity.baremo,
					preciom2: 0,
					totalEurosM2: 0,
					riego: 0,
					coeficiente: entity.coeficiente ?? coeficienteEstudio,
					descripcionDetalle: estudioDetalleDescripcion[index],
					unidadMedida: unidadMedida,
					orden: entity.orden
				})
			})
			:
			[new MaterialCalculo({
				id: undefined,
				materialId: "",
				densidad: "0",
				espesor: "0",
				superficie: "0",
				tmNecesarias: "0",
				volumen: 0,
				costeMezcla: 0,
				mezcla: ZAHORRA_STR,
				precio: "0",
				preciom2: 0,
				totalEurosM2: 0,
				riego: 0,
				coeficiente: coeficienteEstudio,
				descripcionDetalle: ZAHORRA_STR,
				unidadMedida: unidadMedida,
				orden: 1,
				codigoArticulo: 0
			})]);

	const [selectedEquipo, setSelectedEquipo] = useState<UnidadEquipoReadDto>();
	const [selectedTraslado, setSelectedTraslado] = useState<UnidadObraElementoReadDto>();
	const [equiposUnidad, setEquiposUnidad] = useState<UnidadEquipoReadDto[]>([]);
	const [trasladosUnidad, setTrasladosUnidad] = useState<UnidadObraElementoReadDto[]>([]);
	const [equiposCalculos, setEquiposCalculos] = useState<EquipoCalculo[]>(
		estudioDetalleAvanzado?.equipo != undefined ?
			estudioDetalleAvanzado?.equipo?.sort((a, b) => a.orden - b.orden).map(entity => {
				return {
					id: entity.id,
					equipoId: entity.equipoId,
					descripcion: entity.equipoDescripcion,
					unidades: entity.unidades,
					elementos: entity.elementos,
					tarifa: entity.tarifa,
					costeEquipo: 0,
					dias: entity.dias.toString(),
					orden: entity.orden
				}
			})
			: []
	);

	const [trasladosCalculos, setTrasladosCalculos] = useState<EstudioDetalleAvanzadoTraslado[]>(
		estudioDetalleAvanzado?.traslado != undefined ?
			estudioDetalleAvanzado?.traslado?.map(entity => {
				return {
					id: entity.id,
					unidadObraElementoId: entity.unidadObraElementoId,
					descripcion: entity.descripcion,
					unidades: entity.unidades,
					tarifa: entity.tarifa,
					dias: entity.dias,
					coste: 0
				}
			})
			: []
	);

	const [tramosEstudio, setTramosEstudio] = useState<EstudioDetalleAvanzadoTramoDto[]>(
		estudioDetalleAvanzado?.tramo != undefined ?
			estudioDetalleAvanzado?.tramo?.map(entity => {
				return {
					id: entity.id,
					tramoId: entity.tramoId,
					descripcion: entity.descripcion ?? '',
					tarifa: entity.tarifa,
					errors: []
				}
			})
			: [
				new EstudioDetalleAvanzadoTramo({
					id: undefined,
					tramoId: undefined,
					descripcion: 'TRANSPORTE',
					tarifa: '0',
				})
			]
	);

	const [totalToneladas, setTotalToneladas] = useState<number>(materialesCalculos?.reduce((partialSum, a) => partialSum + +a.tmNecesarias, 0) ?? 0);
	let calculoTarifasEquipos = equiposCalculos?.reduce((partialSum, a) => partialSum + a.costeEquipo, 0) ?? 0;
	let calculoTarifasEquiposTn = equiposCalculos?.reduce((partialSum, a) => partialSum + +((a.costeEquipo / totalToneladas).toFixed(2)), 0) ?? 0;
	let calculoTarifasTraslados = trasladosCalculos?.reduce((partialSum, a) => partialSum + a.coste, 0) ?? 0;
	let calculoTarifasTrasladosTn = trasladosCalculos?.reduce((partialSum, a) => partialSum + +((a.coste / totalToneladas).toFixed(2)), 0) ?? 0;
	let totalTramos = tramosEstudio?.reduce((partialSum, a) => partialSum + +a.tarifa, 0) ?? 0;
	const [equipoEuroTm, setEquipoEuroTm] = useState<number>(+(calculoTarifasEquiposTn));
	const [trasladoEuroTm, setTrasladoEuroTm] = useState<number>(+(calculoTarifasTrasladosTn));
	const [equipoTrasladoEuroTm, setEquipoTrasladoEuroTm] = useState<number>(equipoEuroTm + trasladoEuroTm);
	const [diasCalculoAvanzado, setDiasCalculoAvanzado] = useState<number>(estudioDetalleAvanzado?.dias ?? 0);
	const [rdoMedioCalculoAvanzado, setRdoMedioCalculoAvanzado] = useState<number>(totalToneladas / diasCalculoAvanzado);
	const [openModalElemento, setOpenModalElemento] = useState(false);
	const [indexSelected, setIndexSelected] = useState<number>();

	const [descOptions, setDescOptions] = useState<any>([])
	const [openDescModal, setOpenDescModal] = useState<boolean>(false);
	const [descDetalleUnidad, setDescDetalleUnidad] = useState<string>("");
	const [indDetalleUnidad, setIndDetalleUnidad] = useState<number | null>(null);
	const [unidades, setUnidades] = useState<UnidadReadDto[]>([]);
	const [selectedDetalle, setSelectedDetalle] = useState<MaterialCalculo>()
	const [diasRM, setDiasRM] = useState<boolean>(estudioDetalleAvanzado?.diasActualizado ?? false);

	useEffect(() => {
		setDescDetalleUnidad(indDetalleUnidad !== null ? materialesCalculos[indDetalleUnidad]?.descripcionDetalle : "")
	}, [indDetalleUnidad])


	useEffect(() => {
		if (getDelegacion().id != "") {
			fetchUnidades(getDelegacion()?.id);
		}
	}, [getDelegacion]);

	const fetchUnidades = (id: string) => {
		const unidadesRepo: UnidadesRepository = new UnidadesApiRepository(getToken());
		setLoading(true);
		return unidadesRepo.getByDelegacion(id)
			.then((unidadesObraList) => {
				var unidades = unidadesObraList.map((unidad) => {
					return { ...unidad }
				})
				setUnidades(unidades)
			})
			.finally(() => { setLoading(false) });
	}

	const openModalElementos = () => {
		setOpenModalElemento(true);
	}

	const handleCloseElementoEquipo = (elementos: EstudioDetalleAvanzadoElemento[], indexEquipo: number, equipo?: EquipoCalculo) => {
		setOpenModalElemento(false);
		let equipos = [...equiposCalculos];
		equipos[indexEquipo].elementos = elementos;
		equipos[indexEquipo].tarifa = calculateTarifaEquipos(elementos);
		equipos[indexEquipo].costeEquipo = +equipos[indexEquipo].dias * +equipos[indexEquipo].unidades * +equipos[indexEquipo].tarifa;

		calculoTarifasEquiposTn = equipos?.reduce((partialSum, a) => partialSum + +((a.costeEquipo / totalToneladas).toFixed(2)), 0) ?? 0;
		calculoTarifasEquipos = equipos?.reduce((partialSum, a) => partialSum + a.costeEquipo, 0) ?? 0;
		const equipoEuroTm = +calculoTarifasEquiposTn;
		setEquipoEuroTm(equipoEuroTm);
		setEquipoTrasladoEuroTm(equipoEuroTm + trasladoEuroTm);
		setEquiposCalculos(equipos);
	}

	const calculateTarifaEquipos = (elementos: EstudioDetalleAvanzadoElemento[]) => {
		let total = 0
		elementos.map((el) => {
			let elRendimiento = el.rendimiento
			if (elRendimiento) {
				total = total + (+el.tarifa * +elRendimiento)
			}
		});

		return total.toFixed(2);
	}

	const cancelElementoEquipo = () => {
		setOpenModalElemento(false);
	}

	const editEquipo = (equipo: EquipoCalculo, index: number) => {
		setIndexSelected(index);
		openModalElementos();
	}

	const handleClickEquipos = (equipo: UnidadEquipoReadDto) => {
		if ((selectedEquipo?.id !== equipo.id)) {
			setSelectedEquipo(equipo);
		}
	}

	const handleClickTraslados = (traslado: UnidadObraElementoReadDto) => {
		if ((selectedTraslado?.id !== traslado.id)) {
			setSelectedTraslado(traslado);
		}
	}

	useEffect(() => {
		if (estudioDetalleAvanzadoId) {
			fetchEstudioDetalle(estudioDetalleAvanzadoId);
		}

		fetchUnidadObraMateriales(unidadObraId ?? "");
		fetchUnidadObraEquipos(unidadObraId ?? "");
		fetchUnidadObraTraslados(unidadObraId ?? "");

		// calculos equipos
		for (let stepEquipos = 0; stepEquipos < equiposCalculos.length; stepEquipos++) {
			updateDiasEquipos(equiposCalculos[stepEquipos].dias.toString(), stepEquipos);
		}

		// calculos traslados
		for (let stepTraslados = 0; stepTraslados < trasladosCalculos.length; stepTraslados++) {
			updateDiasTraslados(trasladosCalculos[stepTraslados].dias, stepTraslados);
		}

		// calculos materiales
		for (let stepMaterial = 0; stepMaterial < materialesCalculos.length; stepMaterial++) {
			updateMaterial(stepMaterial);
			updateCoeficientes(materialesCalculos[stepMaterial].coeficiente.toString(), stepMaterial);
			updateDescripcion(materialesCalculos[stepMaterial].descripcionDetalle, stepMaterial);
		}
	}, [unidadObraId])

	useEffect(() => {
		const materiales: MaterialCalculo[] = [];
		totalTramos = tramosEstudio?.reduce((partialSum, a) => partialSum + +a.tarifa, 0) ?? 0;

		materialesCalculos.forEach((element, index) => {
			let material = { ...element };
			material.totalEurosM2 = +((+element.precio + equipoTrasladoEuroTm + totalTramos) * (+element.espesor / 100) * +element.densidad).toFixed(2);
			material = updateTotales(new MaterialCalculo(material), equipoTrasladoEuroTm, totalTramos, +unidadMedida.id, CALCULO_AVANZADO_ID_ZAHORRA);
			materiales.push(material)
		});

		setMaterialesCalculos(materiales);
	}, [equipoTrasladoEuroTm, tramosEstudio]);

	useEffect(() => {
		if (estudioDetalles) {
			const materiales: MaterialCalculo[] = [];

			estudioDetalles?.forEach(element => {
				materialesCalculos.forEach((x, index) => {
					if (x.orden === element.orden) {
						let material = { ...x };
						material.descripcionDetalle = element.descripcion;
						material.estudioDetalleId = element.id;
						material = updateTotales(new MaterialCalculo(material), equipoTrasladoEuroTm, totalTramos, +unidadMedida.id, CALCULO_AVANZADO_ID_ZAHORRA);
						materiales.push(material);
					}
				});
			});

			setMaterialesCalculos(materiales);
		}
	}, [estudioDetalles]);

	const fetchEstudioDetalle = useCallback(
		(id: string) => {
			const repo: EstudioDetalleRepository = new EstudioDetalleApiRepository(getToken());
			repo.getByEstudioDetalleAvanzadoId(id).then((resp) => {
				setEstudioDetalles(resp);
			});
		}
		, []);

	const fetchUnidadObraMateriales = useCallback(
		(id: string) => {
			const repo: UnidadesRepository = new UnidadesApiRepository(getToken());

			repo.getMaterialesUnidadConFiltro(id).then((resp) => {
				setMaterialesUnidad([...materialesUnidad, ...resp]);
			});
		}, [])


	const addEquipo = (id: string) => {
		const repo: EquiposRepository = new EquipoApiRepository(getToken());

		repo.getEquipoById(id).then((resp) => {
			setEquiposCalculos([...equiposCalculos, { equipoId: id, tarifa: resp.tarifa, descripcion: resp.descripcion, costeEquipo: 0, orden: Math.max(...(equiposCalculos.map(x => x.orden)), 0) + 1 } as EquipoCalculo]);
		});
	}

	const addTraslado = (id: string) => {
		let resp = trasladosUnidad.find(x => x.id == id);
		setTrasladosCalculos([...trasladosCalculos, { unidadObraElementoId: id, tarifa: resp?.tarifa, descripcion: resp?.descripcion, coste: 0 } as EstudioDetalleAvanzadoTraslado]);
	}

	const fetchUnidadObraEquipos = useCallback(
		(id: string) => {
			const repo: UnidadesRepository = new UnidadesApiRepository(getToken());

			repo.getEquiposUnidadConFiltro(id).then((resp) => {
				setEquiposUnidad([...equiposUnidad, ...resp]);
			});
		}
		, []);

	const fetchUnidadObraTraslados = useCallback(
		(id: string) => {
			const repo: UnidadObraElementoRepository = new UnidadObraElementoApiRepository(getToken());
			if (delegacionId) {
				repo.getUnidadObraElementosConFiltro({
					delegacionIds: [delegacionId],
					tipoElementoIds: [UNIDAD_OBRA_ELEMENTO_TIPO_TRASLADO]
				}).then((resp) => {
					setTrasladosUnidad(resp);
				})
			} else {
				toast.warning("Debe seleccionar una delegación en el estudio para poder añadir traslados");
			}
		}
		, []);

	const updateMaterial = (index: number,
		densidad: string | undefined = undefined,
		espesor: string | undefined = undefined,
		superficie: string | undefined = undefined,
		toneladas: string | undefined = undefined,
		precio: string | undefined = undefined
	) => {
		let { materiales, totalToneladasMateriales } = updateMateriales([...materialesCalculos], index, densidad, espesor, superficie, toneladas, precio, equipoTrasladoEuroTm, totalTramos, unidadMedida, ZAHORRA_STR, ZAHORRA_CALCULOAVANZADOID);

		const toneladasTotal = +(totalToneladasMateriales ?? totalToneladas);
		const equipoEuroTm = +(calculoTarifasEquipos / toneladasTotal).toFixed(2);
		setEquipoEuroTm(equipoEuroTm);
		const trasladoEuroTm = +(calculoTarifasTraslados / toneladasTotal).toFixed(2);
		setTrasladoEuroTm(trasladoEuroTm);
		setEquipoTrasladoEuroTm(equipoEuroTm + trasladoEuroTm);
		setTotalToneladas(toneladasTotal);
		updateDiasCalculoAvanzado(diasCalculoAvanzado);

		materiales[index] = updateTotales(new MaterialCalculo(materiales[index]), equipoTrasladoEuroTm, totalTramos, +unidadMedida.id, CALCULO_AVANZADO_ID_ZAHORRA);
		setMaterialesCalculos(materiales);
	}

	const updateDiasCalculoAvanzado = (dias: number, diasFlag?: boolean ) => {
		if ( diasFlag != undefined ||diasFlag != null ){
			setDiasRM(diasFlag)
		}
		setDiasCalculoAvanzado(dias);
		let rendimiento = updateDiasRendimiento(dias, materialesCalculos[0].tmNecesarias ? +materialesCalculos[0].tmNecesarias : 0)
		setRdoMedioCalculoAvanzado(rendimiento);
		const equipos = [...equiposCalculos];
		const traslados = [...trasladosCalculos];
		updateDias(equipos, traslados);
		setFormErrors({ ...formErrors, dias: undefined });
	};

	const updateDescripcion = (desc: any, index: number) => {
		const material = [...materialesCalculos];
		material[index].descripcionDetalle = desc;
		setMaterialesCalculos(material);
	}

	const updateEquipo = (dias: string | undefined, unidades: string | undefined, index: number) => {
		let equiposCalc = updateEquipos(dias, unidades, index, [...equiposCalculos])
		const traslados = [...trasladosCalculos];
		updateDias(equiposCalc, traslados);
		setEquiposCalculos(equiposCalc);

		if (dias != undefined && !diasRM && index == 0) {
			updateDiasCalculoAvanzado(parseFloat(dias));
		}
	}

	const updateDiasEquipos = (dias: string, index: number) => {
		const equipos = [...equiposCalculos];
		equipos[index].dias = dias;
		let equiposCalc = updateEquipos(equipos[index].dias, "1", index, [...equiposCalculos])
		const traslados = [...trasladosCalculos];
		updateDias(equiposCalc, traslados);
		setEquiposCalculos(equiposCalc);
	}

	const updateDiasTraslados = (dias: string, index: number) => {
		let traslados = updateDiasTraslado(dias, index, [...trasladosCalculos]);
		const equipos = [...equiposCalculos];
		updateDias(equipos, traslados);
		setTrasladosCalculos(traslados);
	}

	const updateTarifaTransporte = (precio: string, index: number) => {
		const tramo = [...tramosEstudio];
		tramo[index].tarifa = precio;
		let materiales = updateTransporte(precio, [...materialesCalculos], equipoTrasladoEuroTm);
		setMaterialesCalculos(materiales);
		setTramosEstudio(tramo);
	}

	const updateDias = (equipos: EquipoCalculo[], traslados: EstudioDetalleAvanzadoTraslado[]) => {
		calculoTarifasEquipos = equipos?.reduce((partialSum, a) => partialSum + a.costeEquipo, 0) ?? 0;
		calculoTarifasEquiposTn = equipos?.reduce((partialSum, a) => +((partialSum + a.costeEquipo / totalToneladas).toFixed(2)), 0) ?? 0;
		const equipoEuroTm = +calculoTarifasEquiposTn;
		setEquipoEuroTm(equipoEuroTm);
		calculoTarifasTraslados = traslados?.reduce((partialSum, a) => partialSum + a.coste, 0) ?? 0;
		calculoTarifasTrasladosTn = traslados?.reduce((partialSum, a) => +((partialSum + a.coste / totalToneladas).toFixed(2)), 0) ?? 0;
		const trasladoEuroTm = +calculoTarifasTrasladosTn;
		setTrasladoEuroTm(trasladoEuroTm);
		setEquipoTrasladoEuroTm(equipoEuroTm + trasladoEuroTm);
		const materiales = [...materialesCalculos];

		materiales.forEach(material => {
			material.totalEurosM2 = +((+material.precio + equipoTrasladoEuroTm + +totalTramos) * (+material.espesor / 100) * +material.densidad).toFixed(2);
		});

		setMaterialesCalculos(materiales);
	}

	const updateCoeficientes = (coeficiente: any, index: number) => {
		const materiales = [...materialesCalculos];
		materiales[index].coeficiente = coeficiente;
		materiales[index] = updateTotales(new MaterialCalculo(materiales[index]), equipoTrasladoEuroTm, totalTramos, +unidadMedida.id, CALCULO_AVANZADO_ID_ZAHORRA);
		setMaterialesCalculos(materiales);
	}

	const deleteEquipo = (index: number) => {
		const repoDetalleEquipo: EstudioDetalleAvanzadoEquipoRepository = new EstudioDetalleAvanzadoEquipoApiRepository(getToken());
		const equipo = [...equiposCalculos];

		if (equiposCalculos[index].id) {
			repoDetalleEquipo.delete(equiposCalculos[index].id!).then((resp) => {
				equipo.splice(index, 1);
				setEquiposCalculos(equipo);
				toast.success("Equipo eliminado correctamente");
				updateDias(equipo, trasladosCalculos);
			});
		} else {
			equipo.splice(index, 1);
			setEquiposCalculos(equipo);
			toast.success("Equipo eliminado correctamente");
			updateDias(equipo, trasladosCalculos);
		}
	}

	const deleteTraslado = (index: number) => {
		const repoDetalle: EstudioDetalleAvanzadoTrasladoRepository = new EstudioDetalleAvanzadoTrasladoApiRepository(getToken());
		const traslado = [...trasladosCalculos];

		if (trasladosCalculos[index].id) {
			repoDetalle.delete(trasladosCalculos[index].id!).then(() => {
				traslado.splice(index, 1);
				setTrasladosCalculos(traslado);
				toast.success("Traslado eliminado correctamente");
				updateDias(equiposCalculos, traslado);
			});
		} else {
			traslado.splice(index, 1);
			setTrasladosCalculos(traslado);
			toast.success("Traslado eliminado correctamente");
			updateDias(equiposCalculos, traslado);
		}
	}

	const closeModal = () => {
		let estudioDetallesArray: EstudioDetalle[] = []
		let material = materialesCalculos?.map(entity => {
			return new EstudioDetalleAvanzadoMaterial({
				id: entity.id,
				codigoArticulo: entity.codigoArticulo,
				materialId: entity.materialId == '00000000-0000-0000-0000-000000000000' ? undefined : entity.materialId,
				densidad: entity.densidad,
				espesor: entity.espesor,
				superficie: entity.superficie,
				toneladas: entity.tmNecesarias,
				volumen: entity.volumen ?? 0,
				baremo: entity.precio,
				materialDescripcion: entity.mezcla,
				riego: entity.riego,
				coeficiente: entity.coeficiente,
				descripcionDetalle: entity.descripcionDetalle,
				orden: entity.orden
			});
		});

		let equipo = equiposCalculos?.map(entity => {
			return new EstudioDetalleAvanzadoEquipo({
				id: entity.id,
				equipoId: entity.equipoId,
				tarifa: entity.tarifa,
				unidades: entity.unidades,
				elementos: entity.elementos,
				dias: entity.dias,
				equipoDescripcion: entity.descripcion,
				orden: entity.orden
			});
		});

		let tramo = tramosEstudio?.map(entity => {
			return new EstudioDetalleAvanzadoTramo({
				tramoId: entity.tramoId,
				descripcion: entity.descripcion,
				tarifa: entity.tarifa
			});
		});

		let estudioAvanzado = new EstudioDetalleAvanzado({
			material: material,
			equipo: equipo,
			tramo: tramo,
			traslado: trasladosCalculos,
			dias: diasCalculoAvanzado,
			diasActualizado: diasRM
		});

		if (validateForm(estudioAvanzado)) {
			materialesCalculos?.map((element, index) => {
				let estudioDet = {} as EstudioDetalle;
				estudioDet.coeficiente = element.coeficiente;
				estudioDet.descripcion = element ? element.descripcionDetalle : '';
				estudioDet.precioCoste = unidadMedida.descripcion === "M3" ? (element.precioCosteM3 ?? 0) :
					unidadMedida.descripcion === "M2" ? (element.precioCosteM2 ?? 0) : element.precioCosteTm ?? 0
				estudioDet.importeCoste = element.importeCoste ?? 0;
				estudioDet.precioVenta = unidadMedida.descripcion === "M3" ? (element.precioVentaM3 ?? 0) :
					unidadMedida.descripcion === "M2" ? (element.precioVentaM2 ?? 0) : element.precioVentaTm ?? 0;
				estudioDet.importeVenta = unidadMedida.descripcion === "M3" ? (element.importeVentaM3 ?? 0) :
					unidadMedida.descripcion === "M2" ? (element.importeVentaM2 ?? 0) : element.importeVentaTm ?? 0;

				estudioDet.estudioDetalleAvanzado = estudioAvanzado;
				estudioDet.medicion = +element.superficie;
				estudioDet.unidadMedida = element.unidadMedida;
				estudioDet.unidadMedidaId = element.unidadMedida.id;
				estudioDet.unidadObraId = unidadObraId ?? "";
				estudioDet.id = element.estudioDetalleId ?? "";
				estudioDet.estudioDetalleAvanzadoId = estudioDetalleAvanzadoId;
				estudioDet.orden = element.orden;
				estudioDet.ordenDetalle = estudiosDetalleLength;

				estudioDetallesArray.push(estudioDet);
			});

			handleCloseModal(estudioDetallesArray);
		}
	}

	const validateForm = (data: EstudioDetalleAvanzado): boolean => {
		let errors: FormErrors<EstudioDetalleAvanzado> = {};
		const fieldIsRequired = "Campo obligatorio.";
		if (!data.dias || data.dias <= 0) {
			errors = { ...errors, dias: fieldIsRequired };
		}

		if (data.material?.find(x => x.descripcionDetalle == "" || !x.descripcionDetalle)) {
			errors = { ...errors, material: fieldIsRequired };
			toast.error("La descrición del material no puede estar vacía");
		}

		setFormErrors(errors);

		return Object.keys(errors).length == 0;
	}

	const handleClickDesc = (item: any, index: number) => {
		setIndDetalleUnidad(index)
		let options = unidades.find((el) => el.calculoAvanzadoId === CALCULO_AVANZADO_ID_ZAHORRA)?.descripcionUnidadObra.filter((elem) => elem.unidadMedida.id === item.unidadMedida.id)
		setOpenDescModal(true)
		setSelectedDetalle(item)
		setDescOptions(options?.map((el) => {
			return {
				text: el.descripcion,
				id: el.id,
				selected: el.descripcion === item.unidadMedida.descripcion,
				onClick: () => {
					setDescDetalleUnidad(el.descripcion)
				}
			}
		}))
	}

	const disableFinalizar = () => {

		if (materialesCalculos.filter(x => Number(x.coeficiente) > 0).length == 0) {
			return true;
		}

		if (estudioDetalleAvanzadoId
			|| materialesCalculos.length >= 1
			&& materialesCalculos.filter(x => x.importeVenta == Infinity || x.importeVenta == 0 || Number.isNaN(x.importeVenta) || x.importeVenta == undefined).length == 0
			&& materialesCalculos.filter(x => Number(x.coeficiente) > 0).length != 0
		) {

			if (equiposCalculos.filter(x => x.costeEquipo == Infinity || x.costeEquipo == 0 || Number.isNaN(x.costeEquipo) || x.costeEquipo == undefined).length == 0
				&& trasladosCalculos.filter(x => x.coste == Infinity || x.coste == 0 || Number.isNaN(x.coste) || x.coste == undefined).length == 0
			) {
				return false
			} else {
				return true
			}
		} else {
			return true;
		}
	}




	return (
		<div>
			<ModalPage
				handleCloseModal={closeModal}
				handleCloseModalCustom={handleCloseCancelModal}
				opened={openModal}
				fields={
					<>
						<h3>Material</h3>
						<div className="double-col-grid">
							<div className="relative">

							</div>
							<div className="flex-horizontal justify-end">
								<FormInput
									label="Días"
									value={diasCalculoAvanzado > 0 ? diasCalculoAvanzado.toString() : ""}
									onChange={(e) => { updateDiasCalculoAvanzado(+e.target.value, true); }}
									className={diasRM ? "input-yellow" : "" } 
									type="number"
									required={true}
									min={0}
									error={formErrors.dias}
									idTest="DiasCalculoAvanzadoInput"
								/>
								<FormInput
									label="Rendimiento medio"
									type="number"
									value={rdoMedioCalculoAvanzado.toFixed(2)}
									onChange={(e) => { }}
									disabled={true}
								/>
							</div>
						</div>

						<table className="mini-table">
							<thead>
								<tr className='table-header'>
									<th>Material</th>
									<th>Densidad</th>
									<th>Espesor (cm)</th>
									<th>Superficie (m2)</th>
									<th>Tm</th>
									<th>Volumen</th>
									<th>Precio/Tm</th>
								</tr>
							</thead>
							<tbody>
								{materialesCalculos?.map((item: MaterialCalculo, index: number, element) => (
									<tr key={index}>
										<td>{ZAHORRA_STR}</td>
										<td>
											<Input className='inline-input number' value={item.densidad ?? ''}
												onChange={(e) => updateMaterial(index, e.target.value)}
												type="number"
												min={0}
												data={`DensidadMaterialInput${index}`}
											/>
										</td>
										<td>
											<Input className='inline-input number' value={item.espesor}
												onChange={(e) => updateMaterial(index, undefined, e.target.value)}
												type="number"
												min={0}
												data={`EspesorMaterialInput${index}`}
											/>
										</td>
										<td>
											<Input className='inline-input number' value={item.superficie}
												onChange={(e) => updateMaterial(index, undefined, undefined, e.target.value)}
												type="number"
												min={0}
												data={`SuperficieMaterialInput${index}`}
											/>
										</td>
										<td>
											<input className='inline-input number'
												value={formatNumber(+item.tmNecesarias)}
												data-cy={`TmNecesarias${index}`}
											/>
										</td>
										<td>
											<div className='inline-input number'>
												{formatNumber(item.volumen!)}
											</div>
										</td>
										<td>
											<Input className='inline-input number' value={item.precio}
												onChange={(e) => updateMaterial(index, undefined, undefined, undefined, undefined, e.target.value)}
												type="number"
												min={0}
												data={`PrecioMaterialInput${index}`}
											/>
										</td>

									</tr>
								))}
							</tbody>
						</table>
						{/* --------------------------------------------------------- */}
						<h3>Equipos</h3>
						<div className="double-col-grid with-add-btn">
							<div className="relative">
								<FormField<DropdownProps>
									handleClick={() => { fetchUnidadObraEquipos(unidadObraId ?? "") }}
									label="Equipos"
									options={equiposUnidad.map(
										(element) => {
											return {
												text: element.descripcion + " - " + formatNumber(element.tarifa),
												id: element.id.toString(),
												selected: selectedEquipo?.id == element.id ? true : false,
												onClick: () => { handleClickEquipos(element); }
											}
										})}
									disabled={false}
									singleSelection={true}
									component={Dropdown}
								/>

								{selectedEquipo &&
									<div className="relative">
										<IconButton
											icon="done"
											onClick={() => addEquipo(selectedEquipo?.equipoId ? selectedEquipo.equipoId : "")}
											className="add-btn transform-translate-y-100"
											label="DoneEquipo"
										/>
									</div>
								}

								<table className="mini-table">
									<thead>
										<tr className='table-header'>
											<th>Equipo</th>
											<th>Tarifa</th>
											<th>Unidades</th>
											<th>Días</th>
											<th>Coste</th>
										</tr>
									</thead>
									<tbody>
										{equiposCalculos?.sort((a,b) => a.orden - b.orden).map((item: EquipoCalculo, index: number,) => (
											<tr key={`equipos-${item.id}-${index}`}>
												<td>
													<TooltipComponent text={item.descripcion}>
														{item.descripcion}
													</TooltipComponent>
												</td>
												<td>
													<div className='inline-input number'>
														{formatNumber(+item.tarifa)}
													</div>
												</td>
												<td>
													<Input className='inline-input number' value={item.unidades ?? ''}
														onChange={(e) => updateEquipo(undefined, e.target.value, index)}
														type="number"
														min={0}
														data={`UnidadesEquipoInput${index}`}
													/>
												</td>
												<td>
													<Input className='inline-input number' value={item.dias ?? ''}
														onChange={(e) => updateEquipo(e.target.value, undefined, index)}
														type="number"
														min={0}
														data={`DiasEquipoInput${index}`}
													/>
												</td>
												<td>
													<div className='inline-input number'>
														{item.costeEquipo > 0 ? formatNumber(item.costeEquipo) : ""}
													</div>
												</td>
												<td>
													<div className="icons-horizontal-inlinegrid">
														<IconButton
															icon="delete"
															onClick={() => deleteEquipo(index)}
														/>
														<IconButton
															icon="edit"
															onClick={() => editEquipo(item, index)}
														/>
													</div>
												</td>

											</tr>
										))}
										<tr>
											<td colSpan={3}>Total equipo</td>
											<td colSpan={1}>
												<div className='inline-input number'>
													{calculoTarifasEquipos > 0 ? formatNumber(calculoTarifasEquipos) : ""}
												</div>
											</td>
											<td colSpan={2}>
												<div className='inline-input number'>
													{equipoEuroTm > 0 ? formatNumber(equipoEuroTm) + " €/Tm" : ""}
												</div>
											</td>
										</tr>

										{openModalElemento ?
											<CalculoAvanzadoElementosEquipo
												openModal={openModalElemento}
												handleCloseModal={handleCloseElementoEquipo}
												handleCloseCancelModal={cancelElementoEquipo}
												equipoCalculo={equiposCalculos[indexSelected!]}
												delegacionId={delegacionId}
												unidadObraId={unidadObraId}
												indexEquipo={indexSelected!}
											/>
											: ""
										}
									</tbody>
								</table>
							</div>
							{/* TRASLADOS */}
							<div className="relative">
								<FormField<DropdownProps>
									label="Traslados"
									options={trasladosUnidad.map(
										(element) => {
											return {
												text: element.descripcion + " - " + formatNumber(element.tarifa),
												id: element.id.toString(),
												selected: selectedTraslado?.id == element.id ? true : false,
												onClick: () => { handleClickTraslados(element); }
											}
										})}
									disabled={false}
									singleSelection={true}
									component={Dropdown}
								/>

								{selectedTraslado &&
									<div className="relative">
										<IconButton
											icon="done"
											onClick={() => addTraslado(selectedTraslado?.id ? selectedTraslado?.id : "")}
											className="add-btn transform-translate-y-100"
											label="DoneTraslado"
										/>
									</div>
								}

								<table className="mini-table">
									<thead>
										<tr className='table-header'>
											<th>Traslado</th>
											<th>Tarifa</th>
											<th>Días</th>
											<th>Coste</th>
										</tr>
									</thead>
									<tbody>
										{trasladosCalculos?.map((item: EstudioDetalleAvanzadoTraslado, index: number) => (
											<tr key={`traslados-${item.id}-${index}`}>
												<td>
													<TooltipComponent text={item.descripcion}>
														{item.descripcion}
													</TooltipComponent>
												</td>
												<td>
													<div className='inline-input number'>
														{formatNumber(item.tarifa)}
													</div>
												</td>
												<td>
													<Input className='inline-input number' value={item.dias ?? ''}
														onChange={(e) => updateDiasTraslados(e.target.value, index)}
														type="number"
														min={0}
														data={`DiasTrasladoInput${index}`}
													/>
												</td>
												<td>
													<div className='inline-input number'>
														{formatNumber(item.coste)}
													</div>
												</td>
												<td>
													<IconButton
														icon="delete"
														onClick={() => deleteTraslado(index)}
													/>
												</td>

											</tr>
										))}
										<tr>
											<td colSpan={3}></td>
											<td colSpan={2}>
												<div className='inline-input number'>
													{trasladoEuroTm > 0 ? formatNumber(trasladoEuroTm) + " €/Tm" : ""}
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						{/* ------------------------------------------------ */}
						<h3>Transporte</h3>

						<table className="mini-table">
							<thead>
								<tr className='table-header'>
									<th>Transporte</th>
									<th>Tarifa</th>
									<th>Toneladas</th>
									<th>Coste</th>
								</tr>
							</thead>
							<tbody>

								{tramosEstudio?.map((item: EstudioDetalleAvanzadoTramoDto, index: number,) => (
									<tr key={index}>
										<td>TRANSPORTE</td>
										<td>
											<Input className='inline-input number' value={item.tarifa ?? ''}
												onChange={(e) => updateTarifaTransporte(e.target.value, index)}
												type="number"
												min={0}
												data={`TarifaTransporteInput${index}`}
											/>
										</td>
										<td>
											<div className='inline-input number'>
												{formatNumber(totalToneladas)}
											</div>
										</td>
										<td>
											<div className='inline-input number'>
												{formatNumber(+item.tarifa * totalToneladas)}
											</div>
										</td>
									</tr>
								))}
								<tr>
									<td colSpan={3}></td>
									<td >
										<div className='inline-input number'>
											{(+tramosEstudio[0].tarifa > 0 || tramosEstudio != null) ? formatNumber((+tramosEstudio[0].tarifa * totalToneladas) / totalToneladas) + " €/Tm" : ""}
										</div>
									</td>
								</tr>
							</tbody>
						</table>

						{/* ------------------------------------------------ */}

						<h3>Totales</h3>
						<table className="mini-table">
							<thead>
								<tr className="table-header">
									<th>Descripción</th>
									<th>Uds</th>
									<th>Medición</th>
									<th>Precio Costes €/tm</th>
									<th>Precio Costes €/m2</th>
									<th>Precio Costes €/m3</th>
									<th>Coeficiente</th>
									<th>Precio Ventas €/Tm</th>
									<th>Precio Ventas €/m2</th>
									<th>Precio Ventas €/m3</th>
								</tr>
							</thead>
							<tbody>
								{materialesCalculos?.map((item: MaterialCalculo, index: number, element) => (
									<tr key={index + (item?.id ?? "")}>
										<td onClick={() => handleClickDesc(item, index)} className="pointer">
											<TooltipComponent text={item.descripcionDetalle}>
												{item.descripcionDetalle}
											</TooltipComponent>
										</td>
										<td>{unidadMedida.descripcion}</td>
										<td>
											<div className='inline-input number'>
												{formatNumber(+item.superficie)}
											</div>
										</td>
										<td>
											{/* {formatNumber(+item.precio + equipoTrasladoEuroTm + totalTramos) != "NaN" ? formatNumber(+(+item.precio + equipoTrasladoEuroTm + totalTramos).toFixed(2)) : 0} */}
											<div className='inline-input number'>
												{item.precioCosteTm ? formatNumber(item.precioCosteTm) : ""}
											</div>
										</td>
										<td>
											{/* {formatNumber(((+item.precio + equipoTrasladoEuroTm + totalTramos) * +item.tmNecesarias) / +item.superficie) != "NaN" ? formatNumber(+(+((+(+(+materialesCalculos[0].precio + equipoTrasladoEuroTm + totalTramos).toFixed(2) * +materialesCalculos[0].tmNecesarias).toFixed(2)) / +materialesCalculos[0].superficie).toFixed(2))) : 0} */}
											<div className='inline-input number'>
												{item.precioCosteM2 ? formatNumber(item.precioCosteM2) : ""}
											</div>
										</td>
										<td>
											{/* {formatNumber((+item.precio + equipoTrasladoEuroTm + totalTramos) * +item.densidad) != "NaN" ? formatNumber(+(+(+materialesCalculos[0].precio + equipoTrasladoEuroTm + totalTramos).toFixed(2) * +materialesCalculos[0].densidad).toFixed(2)) : 0} */}
											<div className='inline-input number'>
												{item.precioCosteM3 ? formatNumber(item.precioCosteM3) : ""}
											</div>

										</td>
										<td>
											<Input className='inline-input number'
												value={item.coeficiente ?? ''}
												onChange={(e) => updateCoeficientes(e.target.value, index)}
												type="number"
												min={0}
												required
											/>
										</td>
										<td>
											{/* {formatNumber((+item.precio + equipoTrasladoEuroTm + totalTramos) / item.coeficiente) != "NaN" ? formatNumber(+(+(+item.precio + equipoTrasladoEuroTm + totalTramos).toFixed(2) / item.coeficiente).toFixed(2)) : 0} */}
											<div className='inline-input number'>
												{item.precioVentaTm ? formatNumber(item.precioVentaTm) : ""}
											</div>
										</td>
										<td>
											{/* {formatNumber((((+item.precio + equipoTrasladoEuroTm + totalTramos) * +item.tmNecesarias) / +item.superficie) / item.coeficiente) != "NaN" ? formatNumber((+(+(+(+((+(+(+materialesCalculos[0].precio + equipoTrasladoEuroTm + totalTramos).toFixed(2) * +materialesCalculos[0].tmNecesarias).toFixed(2)) / +materialesCalculos[0].superficie).toFixed(2)) / materialesCalculos[0].coeficiente).toPrecision(10)).toFixed(2))) : 0} */}
											<div className='inline-input number'>
												{item.precioVentaM2 ? formatNumber(item.precioVentaM2) : ""}
											</div>
										</td>
										<td>
											{/* {formatNumber(((+item.precio + equipoTrasladoEuroTm + totalTramos) * +item.densidad) / item.coeficiente) != "NaN" ? formatNumber(+(+(+(+(+materialesCalculos[0].precio + equipoTrasladoEuroTm + totalTramos).toFixed(2) * +materialesCalculos[0].densidad).toFixed(2) / materialesCalculos[0].coeficiente).toFixed(2) * 100) / 100) : 0} */}
											<div className='inline-input number'>
												{item.precioVentaM3 ? formatNumber(item.precioVentaM3) : ""}
											</div>
										</td>
									</tr>
								))}

								<td colSpan={3}><strong>Totales</strong></td>
								{/* <td>{formatNumber(+(+(+materialesCalculos[0].precio + equipoTrasladoEuroTm + totalTramos).toFixed(2) * +materialesCalculos[0].tmNecesarias).toFixed(2))}</td> */}
								<td>
									<div className='inline-input number'>
										{materialesCalculos[0].importeCosteTm ? formatNumber(materialesCalculos[0].importeCosteTm) : ""}
									</div>
								</td>
								<td>
									<div className='inline-input number'>
										{materialesCalculos[0].importeCoste ? formatNumber(materialesCalculos[0].importeCoste) : ""}
									</div>
								</td>
								{/* <td>{formatNumber(+((+(+(+materialesCalculos[0].precio + equipoTrasladoEuroTm + totalTramos).toFixed(2) * +materialesCalculos[0].densidad).toFixed(2)) * +materialesCalculos[0].volumen!).toFixed(2))}</td> */}
								<td>
									<div className='inline-input number'>
										{materialesCalculos[0].importeCosteM3 ? formatNumber(materialesCalculos[0].importeCosteM3) : ""}
									</div>
								</td>
								<td></td>
								{/* <td>{formatNumber(+(+((+(+materialesCalculos[0].precio + equipoTrasladoEuroTm + totalTramos).toFixed(2) / materialesCalculos[0].coeficiente).toFixed(2)) * +materialesCalculos[0].tmNecesarias).toFixed(2))}</td> */}
								<td>
									<div className='inline-input number'>
										{materialesCalculos[0].importeVentaTm ? formatNumber(materialesCalculos[0].importeVentaTm) : ""}
									</div>
								</td>
								{/* <td>{formatNumber(+(+(+(materialesCalculos[0].totalEurosM2 / (+materialesCalculos[0].espesor / 100) / +materialesCalculos[0].densidad).toFixed(2) * +materialesCalculos[0].tmNecesarias).toFixed(2) / materialesCalculos[0].coeficiente).toFixed(2))}</td> */}
								<td>
									<div className='inline-input number'>
										{materialesCalculos[0].importeVenta ? formatNumber(materialesCalculos[0].importeVenta) : ""}
									</div>
								</td>
								{/* <td>{formatNumber((+(+(+(+(+materialesCalculos[0].precio + equipoTrasladoEuroTm + totalTramos).toFixed(2) * +materialesCalculos[0].densidad).toFixed(2) / materialesCalculos[0].coeficiente).toFixed(2) * +materialesCalculos[0].volumen!).toFixed(2)))}</td> */}
								<td>
									<div className='inline-input number'>
										{materialesCalculos[0].importeVentaM3 ? formatNumber(materialesCalculos[0].importeVentaM3) : ""}
									</div>
								</td>
							</tbody>
						</table>

						<div className="buttons-right">
							<button
								className="btn-primary"
								onClick={() => handleCloseCancelModal()}
							>
								Cancelar
							</button>

							<CustomButton
								title={materialesCalculos.length >= 1 && materialesCalculos.filter(x => x.importeVenta == Infinity || x.importeVenta == 0 || Number.isNaN(x.importeVenta)).length != 0 ? 'Complete el estudio para poder finalizarlo' : 'Finalizar'}
								className="btn-primary"
								btnStyle="accept"
								onButtonClick={() => { closeModal(); }}
								type="button"
								text="Finalizar"
								disabled={disableFinalizar()}
								idCypress="FinalizarCalculoZahorra"
							/>
						</div>
					</>
				}
				modalTitle={"Cálculo Avanzado de ZAHORRA"}
				bodyClassName="--large"
				modalDisable={readOnly}
			>
			</ModalPage>

			{openDescModal ? <Modal
				className="narrow-modal"
				show={openDescModal}
				onHide={() => setOpenDescModal(false)}>
				<ModalHeader>
					<ModalTitle>
						Actualizar descripción
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className="desc-update-container">
						<label>Actualizar descripción:</label>
						<CustomInput
							resizable
							value={descDetalleUnidad}
							className={'platform-input secondary-input'}
							formClassName={"secondary-input"}
							onChange={(e) => {
								setDescDetalleUnidad(e)
							}}
						/>
						<FormField<DropdownProps>
							value={descOptions?.find((item: any) => item?.selected)?.text}
							// value={selectedDetalle?.descripcionDetalle}
							options={descOptions}
							required={true}
							singleSelection={true}
							component={Dropdown}
						/>
					</div>
					<div className="bottom-buttons">
						<CustomButton
							key={`removeCancelModal`}
							title={'Cancelar'}
							className={'btn-primary'}
							onButtonClick={() => {
								setOpenDescModal(false);
								setDescDetalleUnidad("")
								setIndDetalleUnidad(null)
							}}
							text="Cancelar"
						/>
						<CustomButton
							disabled={descDetalleUnidad.trim().length <= 0}
							key={`confirmDeleteModal`}
							title={'Actualizar'}
							className={'btn-primary'}
							btnStyle="delete"
							onButtonClick={() => {
								if (indDetalleUnidad !== null) updateDescripcion(descDetalleUnidad, indDetalleUnidad)
								setOpenDescModal(false)
								setDescDetalleUnidad("")
								setIndDetalleUnidad(null)
							}}
							text="Aceptar"
						/>
					</div>
				</ModalBody>
			</Modal> : ""}
		</div>
	)
}

export default CalculoAvanzadoZahorra;