import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Login/AuthContextProvider";
import { useLoading } from "../../../../context/Loading.Context";
import Estudio from "../../../Estudios/domain/model/Estudio";
import ModalPage from "../../../../components/ui/atoms/ModalPage/ModalPage";
import IconButton from "../../../../components/ui/atoms/Buttons/IconButton";
import EstudioRepository from "../../../Estudios/domain/EstudioRepository";
import EstudioApiRepository from "../../../Estudios/Infraestructure/api/Estudio.ApiRepository";
import { formatDate2input } from "../../../../utils/DateUtil";
import CustomCheckInput from "../../../../components/common/CustomCheckInput";
import CustomInput from "../../../../shared/components/Inputs/CustomInput/CustomInput";
import { CustomButton } from "../../../../shared/components/Buttons/CustomButton";
import Ofertas from "../../../Ofertas/domain/model/Ofertas";
import { formatNumber } from "../../../../utils/NumberUtil";



interface SelectPorOpcionModalSectionProps {
    presupuestoId: string;
    opened?: boolean;
    estudiosGuids?: string[];
    oferta: Ofertas;
    handleCloseModal: (estudioId?: string) => void;
}

const SelectPorOpcionModalSection: React.FC<SelectPorOpcionModalSectionProps> = ({ presupuestoId, opened, estudiosGuids, oferta, handleCloseModal }) => {

    const { getToken, getDelegacion } = useContext(AuthContext);
    const { setLoading } = useLoading();
    const [loaded, setLoaded] = useState<boolean>(true);
    const [estudios, setEstudios] = useState<Estudio[]>([]);
    const [selectedEstudios, setSelectedEstudios] = useState<Estudio[]>([]);
    const [selectedEstudio, setSelectedEstudio] = useState<Estudio>();

    useEffect(() => {
        fetchEstudios(presupuestoId)
    }, []);

    const fetchEstudios = useCallback((id: string) => {
        const repo: EstudioRepository = new EstudioApiRepository(getToken());
        return repo.getByPresupuestoId(id).then((estudiosList) => {

            const estudios = estudiosList.map((estudio) => {
                return { ...estudio, creationTime: estudio.creationTime ? formatDate2input(estudio.creationTime) : '' }
            });

            setEstudios(estudios);

        })
    }, [getToken]);

    const handleChecked = (checked: boolean, estudio: Estudio) => {
        if (checked) {
            setSelectedEstudios(prev => prev.includes(estudio) ? prev : [estudio])
            setSelectedEstudio(estudio)
            // handleContratado(presupuesto);
        } else {
            setSelectedEstudios(prev => prev.filter(item => item !== estudio))
        }
    }

    const calculoTotalPresupuesto = (estudio: Estudio) => {
        if (estudio.totalVenta != undefined) {

            const totalVentas = Number(estudio.totalVenta);

            const gastos = (totalVentas) * (oferta.gastos !== undefined && oferta.gastos > 0 ? (oferta.gastos / 100) : 0);

            const beneficio = (totalVentas) * (oferta.beneficio !== undefined && oferta.beneficio > 0 ? oferta.beneficio / 100 : 0);

            const baseImponible = (totalVentas) + (beneficio) + (gastos);

            const descuento = (baseImponible) * (oferta.descuento !== undefined && oferta.descuento > 0 ? oferta.descuento / 100 : 0);

            const baseImpDescuento = ((baseImponible) - descuento);

            const totalVentaIva = (baseImpDescuento) * (oferta.iva !== undefined && oferta.iva > 0 ? oferta.iva / 100 : 0);

            return Number((baseImpDescuento) + totalVentaIva).toFixed(2)
        }
    }

    return (<>
        <div className="modal-section">
            <div className="fields-modal-container">
                <ModalPage
                    handleCloseModal={handleCloseModal}
                    opened={opened}
                    fields={
                        <>
                            <div className="inline-grid">
                                <div className="inline-grid-header">
                                    <label className="inline-header-checkbox"></label>
                                    <label>Nombre estudio</label>
                                    <label>Total Coste</label>
                                    <label>Total Venta</label>
                                    <label>Total Presupuesto</label>
                                    <label></label>

                                </div>
                                <div className="inline-grid-container ">
                                    {estudios && estudios.map((estudio: Estudio, index: number) => {
                                        return (

                                            <div className={selectedEstudios.includes(estudio) ? "inline-grid-item selected" : "inline-grid-item"}>

                                                <div className="inline-grid-checkbox">
                                                    <CustomCheckInput
                                                        checked={selectedEstudios.includes(estudio)}
                                                        icon={selectedEstudios.includes(estudio) ? "check" : undefined}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChecked(e.target.checked, estudio); }}
                                                    />
                                                </div>

                                                <CustomInput
                                                    key={index}
                                                    value={estudio.descripcion}
                                                    className={'platform-input secondary-input'}
                                                    formClassName={"secondary-input"}
                                                    onChange={(e) => { }}
                                                    disabled={true}

                                                />
                                                <CustomInput
                                                    key={index}
                                                    value={formatNumber(estudio.totalCoste!)}
                                                    className={'platform-input secondary-input'}
                                                    formClassName={"secondary-input"}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                    type="number"

                                                />
                                                <CustomInput
                                                    key={index}
                                                    value={formatNumber(estudio.totalVenta!)}
                                                    className={'platform-input secondary-input'}
                                                    formClassName={"secondary-input"}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                    type="number"

                                                />
                                                <CustomInput
                                                    key={index}
                                                    value={formatNumber(+calculoTotalPresupuesto(estudio)!)}
                                                    className={'platform-input secondary-input'}
                                                    formClassName={"secondary-input"}
                                                    onChange={(e) => { }}
                                                    disabled={true}
                                                    type="number"

                                                />
                                                <label></label>
                                            </div>

                                        )
                                    })}
                                </div>
                                <div className="buttons-right">
                                    <CustomButton
                                        key={`Aceptar`}
                                        title={'Aceptar'}
                                        className={'btn-primary'}
                                        btnStyle="accept"
                                        onButtonClick={() => { handleCloseModal(selectedEstudio?.id) }}
                                        type="button"
                                        text="Aceptar"
                                        disabled={selectedEstudio ? false : true}
                                    />
                                    {/* <CustomButton
                                        key={`Cancelar`}
                                        title={'Cancelar'}
                                        className={'btn-primary'}
                                        btnStyle="accept"
                                        onButtonClick={() => { }}
                                        type="button"
                                        text="Cancelar"
                                    /> */}
                                </div>

                            </div>

                        </>
                    }
                    /*titleShow="Añadir Estudio"*/
                    bodyClassName="--medium"
                    modalTitle={"Selecciona una opcion"}
                >
                </ModalPage>


            </div>
        </div >

    </>
    )
}

export default SelectPorOpcionModalSection