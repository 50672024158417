import { ModalHeader, ModalTitle } from "react-bootstrap";
import { ChangeEvent, useState } from "react";
import IconButton from "../../../../../../../components/ui/atoms/Buttons/IconButton";
import Input from "../../../../../../../components/ui/atoms/Input/FormInput/Input";
import TooltipComponent from "../../../../../../../components/ui/molecules/Tooltip/TooltipComponent";
import BottomButtons from "../../../../../../../components/ui/molecules/Bottom-buttons/BottomButtons";
import LabelButton from "../../../../../../../components/ui/atoms/Buttons/LabelButton";
import { MaterialCalculo } from "../../../../../../../domain/model/Material";
import ModalPage from "../../../../../../../components/ui/atoms/ModalPage/ModalPage";
import { toast } from "react-toastify";

interface Props {
    openModal: boolean,
    handleCloseModal: (materialesCalculos: MaterialCalculo[], totalizado: boolean) => void,
    handleCancelModal: () => void,
    materialCalculoArray: MaterialCalculo[]
}

const CalculoAvanzadoTotalizar: React.FC<Props> = ({ openModal, handleCloseModal, handleCancelModal, materialCalculoArray }) => {

    const [materialesCalculos, setMaterialesCalculos] = useState<MaterialCalculo[]>(materialCalculoArray)


    const handleCloseModalTotalizar = () => {

        if (mismoGrupo(materialesCalculos)) {
            let valoresNull = 0
            let totalizar: boolean = true
            for (const material of materialesCalculos) {
                if (material.totalizarGrupo == undefined) {
                    valoresNull++;
                }

                if (valoresNull == materialesCalculos.length) {
                    totalizar = false
                }

            }
            handleCloseModal(materialesCalculos, totalizar)
        } else {
            toast.error("El grupo debe tener al menos dos materiales para totalizar");
        }

    }

    const mismoGrupo = (materialesCalculos: MaterialCalculo[]) => {
        const valoresMap = new Map<number, number>();
        let valoresNull = 0;
        for (const material of materialesCalculos) {
            if (material.totalizarGrupo != null) {
                if (valoresMap.has(material.totalizarGrupo)) {
                    valoresMap.set(material.totalizarGrupo, valoresMap.get(material.totalizarGrupo)! + 1);
                } else {
                    valoresMap.set(material.totalizarGrupo, 1);
                }
            } else {
                valoresNull++;
            }
        }
        if (valoresNull === materialesCalculos.length) {
            return true;
        }
        if (Array.from(valoresMap.values()).some(count => count === 1)) {
            return false;
        }
        return valoresMap.size > 0;
    };

    const handleEditOrden = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;
        let detalles = [...materialesCalculos];

        detalles[index].totalizarGrupo = +value != 0 ? +value : undefined;


        setMaterialesCalculos(detalles);
    }

    const limpiarGrupos = () => {

        let detalles = [...materialesCalculos];

        detalles.forEach(element => {
            element.totalizarGrupo = undefined
        });
        setMaterialesCalculos(detalles);
    }

    return (
        <div className="modal-section">
            <div className="fields-modal-container">
                <ModalPage
                    className="filters-modal modal-l"
                    bodyClassName="--medium"
                    handleCloseModal={handleCancelModal}
                    handleCloseModalCustom={handleCancelModal}
                    opened={openModal}
                    modalTitle="Totalizar Materiales"
                    hideClose={true}
                    fields={
                        <>
                            <table className="mini-table">
                                <thead>
                                    <tr className="table-header">
                                        <th style={{ width: "20%" }}>Grupos</th>
                                        <th style={{ width: "40%" }}>Descripcion</th>
                                        <th style={{ width: "40%" }}>Descripcion Mezcla</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {materialesCalculos.map((item: MaterialCalculo, index: number) => (
                                        <tr key={index + (item?.id ?? "")}>
                                            <td>
                                                <Input className='inline-input number'
                                                    value={item.totalizarGrupo ?? ''}
                                                    onChange={(e) => (handleEditOrden(e, index))}
                                                    type="number"
                                                    data={`OrdenDetalleInput${index}`}
                                                />
                                            </td>
                                            <td>
                                                <TooltipComponent text={item.descripcionDetalle}>
                                                    <div>
                                                        {item.descripcionDetalle}
                                                    </div>
                                                </TooltipComponent>
                                            </td>
                                            <td>
                                                <TooltipComponent text={item.mezcla}>
                                                    <div>
                                                        {item.mezcla}
                                                    </div>
                                                </TooltipComponent>
                                            </td>
                                            {/* <td></td> */}
                                        </tr>
                                    ))}
                                </tbody>

                            </table>

                            <div className="flex-horizontal">
                                <LabelButton
                                    label="Destotalizar"
                                    onClick={limpiarGrupos}
                                />
                            </div>

                            <BottomButtons>

                                <LabelButton
                                    label="Guardar"
                                    type="submit"
                                    onClick={handleCloseModalTotalizar}

                                // disabled={addDisabled}
                                />

                            </BottomButtons>
                        </>
                    }
                >
                </ModalPage>
            </div>
        </div>
    );
}


export default CalculoAvanzadoTotalizar;