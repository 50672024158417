import { BaseEntity, usePsQuery } from "@pavabits/components";
import { useCallback, useContext } from "react";
import useSearchCallback from "../../../components/ui/atoms/SuggestionInput/hooks/useSearchCallback";
import useSelectorItems, { SelectorType } from "../../../components/ui/atoms/SuggestionInput/hooks/useSelectorItems";
import DelegacionesRepository from "../../../domain/DelegacionesRepository";
import DelegacionesApiRepository from "../../../infraestructure/api/Delegaciones.ApiRepository";
import EstadoOfertaRepository from "../../Clientes/domain/EstadoOfertaRepository";
import EstadoOferta from "../../Clientes/domain/model/EstadoOferta";
import EstadoOfertaApiRepository from "../../Clientes/infraestructure/api/EstadoOferta.ApiRepository";
import { AuthContext } from "../../Login/AuthContextProvider";
import { FilterTag } from "../../Oportunidades/context/FilterFields.Context";
import ContratistaRepository from "../../Oportunidades/domain/ContratistaRepository";
import PromotorRepository from "../../Oportunidades/domain/PromotorRepository";
import ProvinciaRepository from "../../Oportunidades/domain/ProvinciaRepository";
import Contratista from "../../Oportunidades/domain/model/Contratista";
import Promotor from "../../Oportunidades/domain/model/Promotor";
import Provincia from "../../Oportunidades/domain/model/Provincia";
import { DateFilter, SelectorFilter, ValueRangeFilter } from "../../Oportunidades/hooks/useFilters/FilterTypes";
import ContratistaApiRepository from "../../Oportunidades/infraestructure/api/Contratista.ApiRepository";
import PromotorApiRepository from "../../Oportunidades/infraestructure/api/Promotor.ApiRepository";
import ProvinciaApiRepository from "../../Oportunidades/infraestructure/api/ProvinciaApiRepository";
import { OfertasFilterContext } from "../context/Ofertas.Context";
import TipoOfertaRepository from "../domain/TipoOfertaRepository";
import TipoOfertaApiRepository from "../infraestructure/api/TipoOferta.ApiRepository";
import ClienteRepository from "../../Oportunidades/domain/ClienteRepository";
import ClienteApiRepository from "../../Oportunidades/infraestructure/api/Cliente.ApiRepository";
import Cliente from "../../Oportunidades/domain/model/Cliente";
import { COD_DELEGACIONES_CON_TODAS } from "../../../assets/constants/Constantes";
import TipoOferta from "../domain/model/TipoOferta";
import ComercialRepository from "../domain/ComercialRepository";
import ComercialApiRepository from "../infraestructure/api/Comercial.ApiRepository";
import Comercial, { ComercialDtoFilter } from "../domain/model/Comercial";
import CodigoPostalApiRepository from "../../../infraestructure/api/CodigoPostal.ApiRepository";
import CodigoPostal from "../../../domain/model/CodigoPostal";


interface UseSelectorInput extends SelectorFilter { };

interface UseDateInput extends DateFilter { };

interface UseValueRangeInput {
	rangeMin: ValueRangeFilter,
	rangeMax: ValueRangeFilter,
};

export interface UseCheckOutput {
	field: {
		options: { id: string, text: string }[],
		onCheck: (value: any) => void,
		onClear: () => void,
	}
}

export interface UseCheckStrOutput {
	field: {
		options: { id: string, text: string }[],
		onCheck: (value: string) => void,
		onClear: () => void,
	}
}

export interface UseFieldOutput {
	tag: FilterTag,
	field: { input: string, suggestions: SelectorType[], onInputChange: (event: any) => void },
}

export interface UseDropdownOutput {
	tag: FilterTag,
	options: SelectorType[]
}

export interface UseDateOutput {
	tag: FilterTag,
	field: { onDateSelect: (dates: string[]) => void }
}

function useSuggestionSelector<T extends BaseEntity>(fieldInput: UseSelectorInput, callback: (search: string) => Promise<T[]>) {

	const { value, onClear, onChange } = fieldInput;

	const { input, results, onInputChange } = useSearchCallback(callback);

	const { suggestions, text, clearAll } = useSelectorItems(results, value, onChange, false);

	const handleClear = () => {
		clearAll();
		onClear();
	}

	return {
		tag: { text, onClear: handleClear },
		field: { input, suggestions, onInputChange },
	}
}

function useDateSelector(fieldInput: UseDateInput, label: string) {

	const { value, onChange, onClear } = fieldInput;

	const [desde, hasta] = value;

	const text = desde && desde.length && hasta && hasta.length ?
		`${Intl.DateTimeFormat("es-ES").format(new Date(desde))} - ${Intl.DateTimeFormat("es-ES").format(new Date(hasta))}`
		: "";
	return {
		tag: { text, label, onClear },
		field: {
			onDateSelect: onChange,
		}
	}
}

function useDropdownSelector<T extends BaseEntity>(fieldInput: UseSelectorInput, entities: T[]) {

	const { value, onChange, onClear } = fieldInput;

	const { suggestions, text, clearAll } = useSelectorItems(entities, value, onChange, false);

	function handleClear() {
		clearAll();
		onClear();
	}
	return {
		tag: { text, onClear: handleClear },
		options: suggestions
	}
}


function useProvinciaField(fieldInput: UseSelectorInput): UseFieldOutput {

	const { getToken } = useContext(AuthContext);

	const searchProvincias = useCallback((search: string) => {
		const repo: ProvinciaRepository = new ProvinciaApiRepository(getToken());
		return repo.searchProvincias({ filter: { descripcion: search } });
	}, [getToken]);

	const { field, tag } = useSuggestionSelector<Provincia>(fieldInput, searchProvincias);

	return {
		tag: { ...tag, label: "Provincias" },
		field,
	}
}

function useClienteField(fieldInput: UseSelectorInput) {

	const { getToken } = useContext(AuthContext);

	const searchCliente = useCallback((search: string) => {
		const repo: ClienteRepository = new ClienteApiRepository(getToken());
		return repo.searchCliente({ filter: { descripcion: search } });
	}, [getToken]);

	const { field, tag } = useSuggestionSelector<Cliente>(fieldInput, searchCliente);

	return {
		tag: { ...tag, label: "Clientes" },
		field,
	}
}

function useDelegacionesDropdown(fieldInput: UseSelectorInput) {

	const { getToken } = useContext(AuthContext);

	const fetchDelegaciones = useCallback(
		() => {
			const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
			return repo.fetchDelegaciones({
				filter: {
					ids: COD_DELEGACIONES_CON_TODAS
				}
			})
		}, [getToken])

	const { data } = usePsQuery(fetchDelegaciones);

	const { options, tag } = useDropdownSelector(fieldInput, data);

	return {
		tag: { ...tag, label: "Delegaciones" },
		options
	}
}

function useTipoOfertaDropdown(fieldInput: UseSelectorInput) {

	const { getToken } = useContext(AuthContext);

	const { value, onChange, onClear } = fieldInput;

	const fetchTipoOfertas = useCallback(
		() => {
			const repo: TipoOfertaRepository = new TipoOfertaApiRepository(getToken());
			return repo.fetchTipoOfertas();
		}, [getToken])

	const { data } = usePsQuery(fetchTipoOfertas);

	let values = data?.map((elem : TipoOferta) => {
		return {
			id: elem.id,
			text: elem.descripcion,
			value: value && value.includes(elem.text) ,
			onClick: onChange
		}
	})

	const { options, tag } = useDropdownSelector({ value: value ?? [], onChange: onChange, onClear }, values);

	return {
		tag: { ...tag, label: "Tipo Ofertas" },
		options
	}
}

function useComercialDropdown(fieldInput: UseSelectorInput) {
	const {getToken, getDelegacion} = useContext(AuthContext);

	const fetchComerciales = useCallback(() => {
		const repo: ComercialRepository = new ComercialApiRepository((getToken()))
		let filters: ComercialDtoFilter = {
			filter: {
				delegacionId: Number(getDelegacion().id)
			}
		}
		return repo.getAll()
	}, [getToken])

	const {data} = usePsQuery(fetchComerciales);

	const transformedData = data?.map((comercial: Comercial) => {
		return {id: comercial.id, text: comercial.descripcion}}
	)

	const {options, tag} = useDropdownSelector(fieldInput, transformedData)

	return {
		tag: {...tag, label: "Comercial" },
		options
	}
}


function useEstadoDropdown(fieldInput: UseSelectorInput) {
	const {getToken} = useContext(AuthContext);

	const fetchEstadoOferta = useCallback(() => {
		const repo: EstadoOfertaRepository = new EstadoOfertaApiRepository((getToken()))
		return repo.getAll()
	}, [getToken])

	const {data} = usePsQuery(fetchEstadoOferta);

	const transformedData = data?.map((oferta: EstadoOferta) => {
		return {id: oferta.id, text: oferta.descripcion}}
	)

	const {options, tag} = useDropdownSelector(fieldInput, transformedData)

	return {
		tag: {...tag, label: "Estado Oferta" },
		options
	}
}

function usePromotorField(fieldInput: UseSelectorInput): UseFieldOutput {

	const { getToken } = useContext(AuthContext);

	const searchPromotores = useCallback((search: string) => {
		const repo: PromotorRepository = new PromotorApiRepository(getToken());
		return repo.searchPromotores({ filter: { descripcion: search } });
	}, [getToken]);

	const { field, tag } = useSuggestionSelector<Promotor>(fieldInput, searchPromotores);

	return {
		tag: { ...tag, label: "Promotores" },
		field,
	}
}

function useCodigoPostalField(fieldInput: UseSelectorInput): UseFieldOutput {

	const { getToken } = useContext(AuthContext);

	const searchCodigoPostal = useCallback((search: string) => {
		const repo: CodigoPostalApiRepository = new CodigoPostalApiRepository(getToken());
		return repo.fetchCodigoPostal({ filter: { nombreMunicipio: search } });
	}, []);

	const { field, tag } = useSuggestionSelector<CodigoPostal>(fieldInput, searchCodigoPostal);
	return {
		tag: { ...tag, label: "Municipio" },
		field,
	}
}

function useToneladasField(
	fieldInput: UseValueRangeInput,
) {

	const { rangeMin, rangeMax } = fieldInput;
	const closedRange = rangeMin.value && rangeMax.value;

	const toneladasRangeFilter = {
		tag: {
			label: "Toneladas",
			text:
				`${rangeMin.value ?
					`${closedRange ? '' : '>='} ${rangeMin.value} Tm`
					: ''}`
				+ `${closedRange
					? ' - '
					: ''}` +
				`${rangeMax.value ?
					`${closedRange ? '' : '<='} ${rangeMax.value} Tm`
					: ''
				}`,
			onClear: () => {
				rangeMin.onClear();
				rangeMax.onClear();
			}
		},
		onMinChange: rangeMin.onChange,
		onMaxChange: rangeMax.onChange,
	}

	return toneladasRangeFilter;
}

function useFilterFields() {

	const {
		onSelectField,
		onSearchField,
		onRangeField,
		onDateField,
	} = useContext(OfertasFilterContext);

	const provinciaInput = onSelectField("provinciaIds");
	const provinciaFilter = useProvinciaField(provinciaInput);

	const fechaRegistroInput = onDateField("fechaOfertaDesde", "fechaOfertaHasta");
	const fechaPresupuestoInput = onDateField("ultimaModificacionDesde", "ultimaModificacionHasta");

	const fechaRegistroFilter = useDateSelector(fechaRegistroInput, "Fecha Grabación")
	const fechaPresupuestoFilter = useDateSelector(fechaPresupuestoInput, "Fecha Presupuesto")

	const clienteFieldInput = onSelectField("clienteIds");
	const clienteFilter = useClienteField(clienteFieldInput);

	const delegacionInput = onSelectField("delegacionIds");
	const delegacionesFilter = useDelegacionesDropdown(delegacionInput);

	const tipoOfertaInput = onSelectField("tipoOfertaIds");
	const tipoOfertasFilter = useTipoOfertaDropdown(tipoOfertaInput);

	const estadoInput = onSelectField("estadoOfertaIds");
	const estadosFilter = useEstadoDropdown(estadoInput);

	const comercialInput = onSelectField("comercialIds");
	const comercialFilter = useComercialDropdown(comercialInput);

	const promotorInput = onSelectField("promotorIds");
	const promotorFilter = usePromotorField(promotorInput);

	const codigoPostalInput = onSelectField("codigoPostalIds");
	const codigoPostalFilter = useCodigoPostalField(codigoPostalInput);

	const obraDescripcionFilter = onSearchField("obraDescripcion");

	const licitacionFilter = onSearchField("licitacionCodigo");
	const oportunidadFilter = onSearchField("oportunidadCodigo");

	const idOfertaFilter = onSearchField("codigo");

	const toneladasFilter = useToneladasField(
		{
			rangeMin: onRangeField("minToneladas"),
			rangeMax: onRangeField("maxToneladas"),
		}
	);

	return {
		provinciaFilter,
		fechaRegistroFilter,
		fechaPresupuestoFilter,
		clienteFilter,
		toneladasFilter,
		delegacionesFilter,
		tipoOfertasFilter,
		comercialFilter,
		estadosFilter,
		promotorFilter,
		codigoPostalFilter,
		obraDescripcionFilter,
		licitacionFilter,
		idOfertaFilter,
		oportunidadFilter
	}
}

export default useFilterFields;

