import { PropsWithChildren } from "react";
import { SelectorType } from "../../../components/ui/atoms/SuggestionInput/hooks/useSelectorItems";
import { ClienteFieldContext, ComercialesDropdownContext, FechaRegistroContext, FechaPresupuestoContext, OfertaEstadoDropdownContext, OfertasDelegacionesDropdownContext, ProvinciaFieldContext, TagsReadContext, TipoOfertasDropdownContext } from "../../Oportunidades/context/FilterFields.Context";
import useFilterFields from "../hooks/useFilterFields";
import useOfertasTags from "../hooks/useOfertasTags";
import { CodigoPostalFieldContext, IdOfertaFieldContext, LicitacionFieldContext, ObraDescripcionFieldContext, OportunidadFieldContext, PromotorFieldContext } from "./ModalFields.Context";

interface ofertasFilterProps {
	handleUpdateFilter: (array: SelectorType[]) => void

}
const OfertasFilterFieldsProvider: React.FC<ofertasFilterProps> = ({ handleUpdateFilter, children }: PropsWithChildren<ofertasFilterProps>) => {

	const { tags, updateTags } = useOfertasTags();

	const {
		provinciaFilter,
		fechaRegistroFilter,
		fechaPresupuestoFilter,
		clienteFilter,
		toneladasFilter,
		delegacionesFilter,
		tipoOfertasFilter,
		estadosFilter,
		comercialFilter,
		promotorFilter,
		codigoPostalFilter,
		obraDescripcionFilter,
		licitacionFilter,
		idOfertaFilter,
		oportunidadFilter,

	} = useFilterFields();



	return (
		<>
			<ProvinciaFieldContext.Provider value={provinciaFilter.field}>
				<FechaRegistroContext.Provider value={fechaRegistroFilter.field}>
					<ClienteFieldContext.Provider value={clienteFilter.field}>
						<FechaPresupuestoContext.Provider value={fechaPresupuestoFilter.field}>
							<OfertaEstadoDropdownContext.Provider value={estadosFilter.options}>
								<ComercialesDropdownContext.Provider value={comercialFilter.options}>
									<TipoOfertasDropdownContext.Provider value={tipoOfertasFilter.options}>
										<OfertasDelegacionesDropdownContext.Provider value={delegacionesFilter.options}>
											<PromotorFieldContext.Provider value={promotorFilter.field}>
												<CodigoPostalFieldContext.Provider value={codigoPostalFilter.field}>
													<ObraDescripcionFieldContext.Provider value={{ onSearch: obraDescripcionFilter.onChange, onFilter: updateTags, onClear: () => { obraDescripcionFilter.onClear() } }}>
														<LicitacionFieldContext.Provider value={{ onSearch: licitacionFilter.onChange, onFilter: updateTags, onClear: () => { licitacionFilter.onClear() } }}>
															<IdOfertaFieldContext.Provider value={{ onSearch: idOfertaFilter.onChange, onFilter: updateTags, onClear: () => { idOfertaFilter.onClear() } }}>
																<OportunidadFieldContext.Provider value={{ onSearch: oportunidadFilter.onChange, onFilter: updateTags, onClear: () => { oportunidadFilter.onClear() } }}>
																	<TagsReadContext.Provider value={
																		{
																			tags: tags.concat(provinciaFilter.tag,
																				clienteFilter.tag,
																				fechaRegistroFilter.tag,
																				fechaPresupuestoFilter.tag,
																				toneladasFilter.tag,
																				tipoOfertasFilter.tag,
																				estadosFilter.tag,
																				promotorFilter.tag,
																				codigoPostalFilter.tag,
																				delegacionesFilter.tag,
																				comercialFilter.tag
																			)
																		}
																	}>
																		{children}
																	</TagsReadContext.Provider>
																</OportunidadFieldContext.Provider>
															</IdOfertaFieldContext.Provider>
														</LicitacionFieldContext.Provider>
													</ObraDescripcionFieldContext.Provider>
												</CodigoPostalFieldContext.Provider>
											</PromotorFieldContext.Provider>
										</OfertasDelegacionesDropdownContext.Provider>
									</TipoOfertasDropdownContext.Provider>
								</ComercialesDropdownContext.Provider>
							</OfertaEstadoDropdownContext.Provider>
						</FechaPresupuestoContext.Provider>
					</ClienteFieldContext.Provider>
				</FechaRegistroContext.Provider>
			</ProvinciaFieldContext.Provider>
		</>
	)
}

export default OfertasFilterFieldsProvider;